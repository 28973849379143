import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Divider, Radio, Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { isArray } from 'lodash';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { shadeColor } from '../../../../../helpers';
import { CHAT_STATUS } from '../../../../../constants';
import AssignToModal from '../../RightBarModals/AssignToModal';
import styles from './ResolveChatButton.module.scss';
import { withStore } from '../../../../../store/rootStore';
import { AiOutlineSetting } from 'react-icons/ai';
import SelectBotModal from './SelectBotModal';
const { Text } = Typography;
var AfterResolveChatAction;
(function (AfterResolveChatAction) {
    AfterResolveChatAction["NONE"] = "none";
    AfterResolveChatAction["SEND_CLOSING_MESSAGE"] = "sendClosingMessage";
    AfterResolveChatAction["RUN_BOT"] = "runBot";
})(AfterResolveChatAction || (AfterResolveChatAction = {}));
const LOCAL_STORAGE_KEY_AFTER_RESOLVE_CHAT_ACTION = 'afetrResolveChatAction';
const LOCAL_STORAGE_KEY_SELECTED_BOT_NODE = 'selectedBotNode';
class ChatStatusButtons extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "closingMessageButtonRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "closingMessageOptionsRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "containerRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "assignRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "localStorageGetAfterResolveAction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                let resolveAfterAction = (localStorage.getItem(LOCAL_STORAGE_KEY_AFTER_RESOLVE_CHAT_ACTION) || AfterResolveChatAction.SEND_CLOSING_MESSAGE);
                // Backward compatibility
                const sendClosingMessage = localStorage.getItem('sendClosingMessage');
                if (sendClosingMessage) {
                    resolveAfterAction = JSON.parse(sendClosingMessage) ? AfterResolveChatAction.SEND_CLOSING_MESSAGE : AfterResolveChatAction.NONE;
                }
                return resolveAfterAction;
            }
        });
        Object.defineProperty(this, "localStorageGetSelectedBotNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return localStorage.getItem(LOCAL_STORAGE_KEY_SELECTED_BOT_NODE) || '';
            }
        });
        Object.defineProperty(this, "handleClickOutside", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                var _a, _b, _c, _d, _e, _f, _g;
                if (this.closingMessageButtonRef &&
                    !((_b = (_a = this.closingMessageButtonRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.contains(e.target)) &&
                    this.closingMessageOptionsRef &&
                    !((_d = (_c = this.closingMessageOptionsRef) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.contains(e.target))) {
                    this.setState({ openClosingMessageOptions: false });
                }
                if (this.containerRef && !((_f = (_e = this.containerRef) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.contains(e.target)) && this.props.onComplete) {
                    if (!((_g = document.querySelector('.moreButtonMobile')) === null || _g === void 0 ? void 0 : _g.contains(e.target))) {
                        this.props.onComplete();
                    }
                }
            }
        });
        Object.defineProperty(this, "onHover", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (hovered) => {
                this.setState({ hovered });
            }
        });
        Object.defineProperty(this, "handleOnClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => {
                this.setState({ loading: true, openClosingMessageOptions: false });
                const { afterResolveAction, selectedBotNode } = this.state;
                this.props.store.chatsStore
                    .resolveChat(chatId, afterResolveAction === AfterResolveChatAction.SEND_CLOSING_MESSAGE, afterResolveAction === AfterResolveChatAction.RUN_BOT, selectedBotNode)
                    .then(() => {
                    this.setState({ loading: false });
                    if (this.props.onComplete) {
                        this.props.onComplete();
                    }
                })
                    .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Error: resolving chat failed', chatId, error);
                });
            }
        });
        Object.defineProperty(this, "toggleAssignModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (newState) => {
                if (newState === false && this.props.onComplete) {
                    this.props.onComplete();
                }
                this.setState({
                    isAssignModalVisible: newState,
                });
            }
        });
        Object.defineProperty(this, "toggleSelectBotModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (visible) => {
                this.setState({
                    isSelectBotModalVisible: visible,
                });
            }
        });
        Object.defineProperty(this, "isAssignToDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat: activeChat, chatAccess, filePreview } = this.props.store.activeChatStore;
                return this.state.loading || !activeChat || !(chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) || filePreview;
            }
        });
        Object.defineProperty(this, "isMarkSolvedDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat: activeChat, chatAccess, filePreview } = this.props.store.activeChatStore;
                return this.state.loading || !activeChat || activeChat.status === CHAT_STATUS.RESOLVED || !(chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) || filePreview;
            }
        });
        Object.defineProperty(this, "onAfterActionChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                // Backward compatibility
                // Not need to keep in local storage if changes in new version
                if (localStorage.getItem('sendClosingMessage')) {
                    localStorage.removeItem('sendClosingMessage');
                }
                this.setState({
                    afterResolveAction: value,
                    openClosingMessageOptions: false,
                }, () => {
                    localStorage.setItem(LOCAL_STORAGE_KEY_AFTER_RESOLVE_CHAT_ACTION, value);
                });
                if (value === AfterResolveChatAction.RUN_BOT && !this.state.selectedBotNode) {
                    this.toggleSelectBotModal(true);
                }
            }
        });
        Object.defineProperty(this, "openBotNodeSelectModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.stopPropagation();
                e.preventDefault();
                if (this.props.store.activeChatStore.sessionIsOver)
                    return;
                this.toggleSelectBotModal(true);
            }
        });
        Object.defineProperty(this, "onBotNodeSelect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                localStorage.setItem(LOCAL_STORAGE_KEY_SELECTED_BOT_NODE, id);
                this.setState({ selectedBotNode: id });
                if (this.state.afterResolveAction !== AfterResolveChatAction.RUN_BOT) {
                    this.onAfterActionChange(AfterResolveChatAction.RUN_BOT);
                }
            }
        });
        Object.defineProperty(this, "openClosingMessageOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.closingMessageButtonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                this.setState({ openClosingMessageOptions: !this.state.openClosingMessageOptions });
            }
        });
        Object.defineProperty(this, "getBackgroundColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { hovered } = this.state;
                const { theme } = this.props.store.activeChatStore;
                if (!theme.color) {
                    return {
                        backgroundColor: '#fff',
                        background: '#fff',
                        backgroundInverted: '#fff',
                    };
                }
                let backgroundColor = isArray(theme.color) ? [theme.color[0], theme.color[1]] : theme.color;
                if (hovered) {
                    if (isArray(theme.color)) {
                        backgroundColor[0] = shadeColor(theme.color[0], -25);
                        backgroundColor[1] = shadeColor(theme.color[1], -25);
                    }
                    else {
                        backgroundColor = shadeColor(theme.color, -25);
                    }
                }
                const background = isArray(backgroundColor)
                    ? `linear-gradient(270deg, ${backgroundColor[0]} 0%, ${backgroundColor[1]} 100%)`
                    : backgroundColor;
                const backgroundInverted = isArray(backgroundColor)
                    ? `linear-gradient(90deg, ${backgroundColor[0]} 1.25%, ${backgroundColor[1]} 100%)`
                    : backgroundColor;
                return { backgroundColor, background, backgroundInverted };
            }
        });
        Object.defineProperty(this, "renderResolveButtonContents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { loading, afterResolveAction, selectedBotNode } = this.state, { sessionIsOver, theme } = this.props.store.activeChatStore;
                if (loading) {
                    return React.createElement(LoadingOutlined, null);
                }
                if (!sessionIsOver && afterResolveAction !== AfterResolveChatAction.NONE) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: classnames(styles.buttonBoldText, {
                                [styles.buttonGreyText]: this.isMarkSolvedDisabled(),
                                [styles.invertColors]: !this.isMarkSolvedDisabled() &&
                                    (theme.invertColors || ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.secondaryColor)),
                            }) },
                            React.createElement(Trans, { i18nKey: "markAsSolved" })),
                        React.createElement("div", { className: classnames(styles.buttonText, {
                                [styles.buttonGreyText]: this.isMarkSolvedDisabled(),
                                [styles.invertColors]: !this.isMarkSolvedDisabled() &&
                                    (theme.invertColors || ((_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.secondaryColor)),
                            }) },
                            React.createElement(Trans, Object.assign({ i18nKey: afterResolveAction === AfterResolveChatAction.SEND_CLOSING_MESSAGE
                                    ? 'andSendClosingMessage'
                                    : 'andRunBot' }, (afterResolveAction === AfterResolveChatAction.RUN_BOT) && {
                                values: { botNode: selectedBotNode }
                            })))));
                }
                return React.createElement(Trans, { i18nKey: "markAsSolved" });
            }
        });
        const { chat } = this.props.store.activeChatStore;
        this.state = {
            loading: false,
            isAssignModalVisible: false,
            isSelectBotModalVisible: false,
            openClosingMessageOptions: false,
            chatId: chat ? chat._id : null,
            hovered: false,
            afterResolveAction: this.localStorageGetAfterResolveAction(),
            selectedBotNode: this.localStorageGetSelectedBotNode(),
        };
        this.closingMessageButtonRef = React.createRef();
        this.closingMessageOptionsRef = React.createRef();
        this.containerRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const { sessionIsOver } = this.props.store.activeChatStore;
        if (sessionIsOver) {
            this.setState({
                afterResolveAction: AfterResolveChatAction.NONE,
            });
        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    componentDidUpdate() {
        const { chat: activeChat, sessionIsOver } = this.props.store.activeChatStore;
        if (sessionIsOver && this.state.afterResolveAction !== AfterResolveChatAction.NONE) {
            this.setState({ afterResolveAction: AfterResolveChatAction.NONE });
        }
        else if (activeChat && activeChat._id !== this.state.chatId
            || !activeChat && this.state.chatId) {
            this.setState({
                chatId: activeChat ? activeChat._id : null,
                afterResolveAction: sessionIsOver
                    ? AfterResolveChatAction.NONE
                    : this.localStorageGetAfterResolveAction(),
            });
        }
    }
    render() {
        var _a;
        const { visible, store } = this.props;
        const { chat: activeChat, chatAccess, sessionIsOver, theme } = store.activeChatStore;
        const { isAssignModalVisible, isSelectBotModalVisible, openClosingMessageOptions, afterResolveAction, } = this.state;
        const { background, backgroundInverted } = this.getBackgroundColor();
        return (React.createElement("div", { ref: this.containerRef, style: { display: !visible ? 'none' : 'flex' }, className: classnames(styles.resolveChatContainer, {
                [styles.hiddenResolveChatContainer]: isAssignModalVisible,
            }) },
            React.createElement(Tooltip, { title: !(chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) ? React.createElement(Trans, { i18nKey: "noChatManagePermission" }) : null, placement: "top" },
                React.createElement("div", { className: styles.resolveChatButtonsBlock },
                    React.createElement(Button, { disabled: this.isAssignToDisabled(), ref: (assignRef) => {
                            this.assignRef = assignRef;
                        }, className: classnames('big-button white-button', styles.resolveButton, {
                            'white-button-disabled': this.isAssignToDisabled(),
                        }), onClick: () => {
                            var _a;
                            (_a = this.assignRef) === null || _a === void 0 ? void 0 : _a.blur();
                            this.toggleAssignModal(true);
                        } },
                        React.createElement(Trans, { i18nKey: "assignToDots" })),
                    React.createElement("div", { className: styles.resolveButtonWrapper, onMouseEnter: () => this.onHover(true), onMouseLeave: () => this.onHover(false) },
                        React.createElement(Button, { disabled: this.isMarkSolvedDisabled(), className: classnames('big-green-button', styles.resolveButton, {
                                [styles.invertColors]: theme.invertColors || ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.secondaryColor),
                            }), onClick: () => activeChat && this.handleOnClick(activeChat._id), style: { background } }, this.renderResolveButtonContents()),
                        React.createElement(Button, { ref: this.closingMessageButtonRef, disabled: this.isMarkSolvedDisabled(), className: classnames('big-green-button', styles.secondaryOptionsButton), onClick: this.openClosingMessageOptions, style: { background: backgroundInverted } },
                            React.createElement(DownOutlined, { className: classnames({
                                    [styles.invertColors]: theme.invertColors,
                                    [styles.arrowOpen]: openClosingMessageOptions,
                                }) }))))),
            openClosingMessageOptions && (React.createElement("div", { ref: this.closingMessageOptionsRef, className: styles.sendMessageContainer },
                React.createElement(Text, { className: styles.radioHeader },
                    React.createElement(Trans, { i18nKey: "afterClickOnButton" })),
                React.createElement(Divider, { className: styles.radioDivider }),
                React.createElement(Radio.Group, { onChange: (e) => this.onAfterActionChange(e.target.value), value: !sessionIsOver
                        ? afterResolveAction
                        : AfterResolveChatAction.NONE },
                    React.createElement(Radio, { className: styles.radioOption, value: AfterResolveChatAction.NONE },
                        React.createElement(Trans, { i18nKey: "onlyResolveChat" })),
                    React.createElement(Divider, { className: styles.radioDivider }),
                    React.createElement(Radio, { className: styles.radioOption, disabled: sessionIsOver, value: AfterResolveChatAction.SEND_CLOSING_MESSAGE },
                        React.createElement(Text, { className: classnames(styles.radioOptionText, {
                                [styles.disabled]: sessionIsOver,
                            }) },
                            React.createElement(Trans, { i18nKey: "resolveChatAndSendClosingMessage" }))),
                    React.createElement(Divider, { className: styles.radioDivider }),
                    React.createElement(Radio, { className: styles.radioOption, disabled: sessionIsOver, value: AfterResolveChatAction.RUN_BOT },
                        React.createElement(Text, { className: classnames(styles.radioOptionText, {
                                [styles.disabled]: sessionIsOver,
                            }) },
                            React.createElement(Trans, { i18nKey: "resolveChatAndRunBot" }),
                            React.createElement(Tooltip, { title: !sessionIsOver ? React.createElement(Trans, { i18nKey: "selectBotNode" }) : '', placement: "topLeft" },
                                React.createElement(AiOutlineSetting, { onClick: this.openBotNodeSelectModal }))))))),
            React.createElement(AssignToModal, { isAssignModalVisible: isAssignModalVisible, toggleModal: this.toggleAssignModal, assignFunction: store.chatsStore.assignChat }),
            React.createElement(SelectBotModal, { visible: isSelectBotModalVisible, closeModal: () => this.toggleSelectBotModal(false), onBotNodeSelect: this.onBotNodeSelect, selectedBotNode: this.state.selectedBotNode })));
    }
}
export default withStore(observer(ChatStatusButtons));
