const LOCAL_STORAGE_TEMPLATES_LAST_SENT_KEY = 'templates_last_sent';
export const updateLastSent = (templateName, accountId) => {
    const id = `${accountId}_${templateName}`;
    const lastSent = localStorage.getItem(LOCAL_STORAGE_TEMPLATES_LAST_SENT_KEY);
    const newLastSent = lastSent ? JSON.parse(lastSent) : {};
    newLastSent[id] = new Date().getTime();
    localStorage.setItem(LOCAL_STORAGE_TEMPLATES_LAST_SENT_KEY, JSON.stringify(newLastSent));
};
/**
 * Sort templates by name with some nuances
 *
 * @param templateA
 * @param templateB
 */
export function sortByName(templateA, templateB) {
    if (templateA.title === templateB.title) {
        return sortByDateCreated(templateA, templateB);
    }
    // This part derived from TemplateMessagesStore.sortTemplates() in v4
    // I'm not sure it needed
    if (templateA.title.startsWith('Template') && templateB.title.startsWith('Template')) {
        const firstTemplNumber = parseInt(templateA.title.replace('Template', ''));
        const secondTemplNumber = parseInt(templateB.title.replace('Template', ''));
        return firstTemplNumber - secondTemplNumber;
    }
    return templateA.title.localeCompare(templateB.title);
}
export function sortByLastUsed(templateMessagesList, selectedAccountId) {
    return function (templateA, templateB) {
        const lastSent = localStorage.getItem(LOCAL_STORAGE_TEMPLATES_LAST_SENT_KEY);
        const lastSentObj = lastSent ? JSON.parse(lastSent) : {};
        const accoundIdA = Object.keys(templateMessagesList).find((accountId) => accountId == selectedAccountId && templateMessagesList[accountId].find(({ name }) => templateA.name === name));
        const accoundIdB = Object.keys(templateMessagesList).find((accountId) => accountId === selectedAccountId && templateMessagesList[accountId].find(({ name }) => templateB.name === name));
        const idA = `${accoundIdB}_${templateB.name}`;
        const idB = `${accoundIdA}_${templateA.name}`;
        const lastSentA = lastSentObj[idA];
        const lastSentB = lastSentObj[idB];
        if (lastSentA === lastSentB) {
            return sortByDateCreated(templateA, templateB);
        }
        return (lastSentA || 0) - (lastSentB || 0);
    };
}
export function sortByDateCreated(templateA, templateB) {
    return templateB.createdAt.getTime() - templateA.createdAt.getTime();
}
export function filterTemplates({ onlyStatus, showNew = true, onlyUsage, department, }) {
    return (template) => {
        const { status } = template.obj.localizations[0];
        if (status) {
            if (onlyStatus && !onlyStatus.includes(status)) {
                return false;
            }
        }
        else if (!showNew) {
            return false;
        }
        if (onlyUsage && onlyUsage !== template.usage) {
            return false;
        }
        if (department && !template.departments.includes(department)) {
            return false;
        }
        return true;
    };
}
export const filterByName = (search) => (template) => {
    return template.title.toLowerCase().includes(search.toLowerCase());
};
