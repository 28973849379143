export * from '@whatsper/texterchat-common/constants';
import packageJson from '../package.json';
export const VERSION = packageJson.version;
console.log("Inbox VERSION: ", VERSION);
export const MOBILE_WIDTH = 1026;
export const DESKTOP_WIDTH = 1600;
export var INPUT_BLOCK;
(function (INPUT_BLOCK) {
    INPUT_BLOCK["ACCEPT"] = "ACCEPT";
    INPUT_BLOCK["SESSION_OVER"] = "SESSION_OVER";
    INPUT_BLOCK["COMPOSE"] = "COMPOSE";
    INPUT_BLOCK["BLOCKED"] = "BLOCKED";
})(INPUT_BLOCK || (INPUT_BLOCK = {}));
// This is awful shitcode
/**
 * @deprecated This needs to be removed, not adde to new code
 */
export const ADDITIONAL_CHAT_STATUS = {
    PENDING_SELF: 6
};
export const STATISTICS_TABS = {
    TODAY: 0,
    LAST_WEEK: 1,
    LAST_MONTH: 2,
};
export const FILTER_TABS = {
    ALL_CHATS: 'ALL_CHATS',
    WHATSAPP: 'WHATSAPP',
    MESSENGER: 'MESSENGER',
    INSTAGRAM: 'INSTAGRAM'
};
export const FILTER_DEPARTMENTS = {
    ALL_DEPARTMENTS: 'ALL_DEPARTMENTS',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    OTHER: 'OTHER'
};
export const FILTER_AGENTS = {
    ALL_AGENTS: 'ALL_AGENTS',
    NOT_ASSIGNED: 'WITH_BOT',
    SPECIFIC_AGENT: 'SPECIFIC_AGENT',
    WITH_BOT: 'WITH_BOT'
};
export const SERVER_URL = process.env.REACT_APP_SERVER_URL || window.env.server_url || `${window.location.origin}/server`;
const [, , SERVER_HOST, , port] = SERVER_URL.match(/^http(s)?:\/\/([a-z0-9\.-]+)(:([0-9]+))?/i) || [];
const SERVER_PORT = port ? parseInt(port) : 80;
export { SERVER_HOST, SERVER_PORT };
export const API_PATH = {
    START_SESSION: '/auth/session',
    END_SESSION: '/auth/session/clear',
    HEARTBEAT: '/auth/heartbeat',
    AUTH_PASSWORD: '/auth/methods/password',
    AUTH_ME: '/auth/me',
    AUTH_LOGOUT: '/auth/logout',
    FILES: '/files',
    BOTS: '/bots',
    BOTS_MANAGE: '/bots/manage',
    API_TOKENS: '/api_tokens',
    CHATS: '/chats',
    CRM: '/crm',
    TEMPLATES: '/whatsapp/templates',
    USERS: '/users',
    ROLES: '/roles',
    DEPARTMENTS: '/departments',
    MESSAGES: '/messages',
    QUICK_REPLIES: '/quick_replies',
    NOTIFICATIONS: '/notifications',
    SETTINGS: '/settings',
    AUTH: '/auth',
    CHANNELS: '/channels',
    PERSONS: '/persons',
    EVENTS_STREAM: '/events/stream',
    BULK_REPORTS: '/templates/bulk/reports',
    STATS: '/stats',
    BUILD_ID: '/build-info.json',
    SCHEDULE: '/schedule',
};
export const USER_STATES = {
    LOGGED_IN: 1,
    LOGGED_OUT: 2,
};
export var APP_STATE;
(function (APP_STATE) {
    APP_STATE[APP_STATE["INIT"] = 0] = "INIT";
    APP_STATE[APP_STATE["READY"] = 1] = "READY";
    APP_STATE[APP_STATE["ERROR"] = 2] = "ERROR";
})(APP_STATE || (APP_STATE = {}));
export var WIZARD_STEPS;
(function (WIZARD_STEPS) {
    WIZARD_STEPS[WIZARD_STEPS["ACCOUNT_STEP"] = 0] = "ACCOUNT_STEP";
    WIZARD_STEPS[WIZARD_STEPS["FIRST_STEP"] = 1] = "FIRST_STEP";
    WIZARD_STEPS[WIZARD_STEPS["SECOND_STEP"] = 2] = "SECOND_STEP";
    WIZARD_STEPS[WIZARD_STEPS["THIRD_STEP"] = 3] = "THIRD_STEP";
    WIZARD_STEPS[WIZARD_STEPS["FOURTH_STEP"] = 4] = "FOURTH_STEP";
    WIZARD_STEPS[WIZARD_STEPS["FIFTH_STEP"] = 5] = "FIFTH_STEP";
    WIZARD_STEPS[WIZARD_STEPS["SIXTH_STEP"] = 6] = "SIXTH_STEP";
})(WIZARD_STEPS || (WIZARD_STEPS = {}));
export const TIME_FORMAT = {
    TWELVE_HOURS: "TWELVE_HOURS",
    TWENTY_FOUR_HOURS: "TWENTY_FOUR_HOURS",
};
export var PHONE_FORMAT;
(function (PHONE_FORMAT) {
    PHONE_FORMAT["SMART"] = "SMART";
    PHONE_FORMAT["INTERNATIONAL"] = "INTERNATIONAL";
    PHONE_FORMAT["NATIONAL"] = "NATIONAL";
})(PHONE_FORMAT || (PHONE_FORMAT = {}));
export const CRM_STATUS = {
    ACTIVE: "ACTIVE",
    NOT_ACTIVE: "NOT_ACTIVE",
};
export var TEMPLATE_MESSAGE_STATUS;
(function (TEMPLATE_MESSAGE_STATUS) {
    TEMPLATE_MESSAGE_STATUS["REQUESTED"] = "REQUESTED";
    TEMPLATE_MESSAGE_STATUS["SUBMIT_FAILED"] = "SUBMIT_FAILED";
    TEMPLATE_MESSAGE_STATUS["PENDING"] = "PENDING";
    TEMPLATE_MESSAGE_STATUS["APPROVED"] = "APPROVED";
    TEMPLATE_MESSAGE_STATUS["REJECTED"] = "REJECTED";
    TEMPLATE_MESSAGE_STATUS["DELETION_PENDING"] = "DELETION_PENDING";
    TEMPLATE_MESSAGE_STATUS["DELETED"] = "DELETED";
    TEMPLATE_MESSAGE_STATUS["SUBMITTED"] = "SUBMITTED";
})(TEMPLATE_MESSAGE_STATUS || (TEMPLATE_MESSAGE_STATUS = {}));
export var TEMPLATE_USAGE;
(function (TEMPLATE_USAGE) {
    TEMPLATE_USAGE["INBOX"] = "inbox";
    TEMPLATE_USAGE["BULK"] = "bulk";
})(TEMPLATE_USAGE || (TEMPLATE_USAGE = {}));
export const TEMPLATE_RADIO_STATUSES = {
    TAKEN: 1,
    OTHER: 2
};
export var TEMPLATE_SORT;
(function (TEMPLATE_SORT) {
    TEMPLATE_SORT[TEMPLATE_SORT["NAME"] = 1] = "NAME";
    TEMPLATE_SORT[TEMPLATE_SORT["DATE_CREATED"] = 2] = "DATE_CREATED";
    TEMPLATE_SORT[TEMPLATE_SORT["LAST_USED"] = 3] = "LAST_USED";
})(TEMPLATE_SORT || (TEMPLATE_SORT = {}));
export const RICH_TEMPLATE_HEADER_TYPE = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    DOCUMENT: 'DOCUMENT',
};
export const RICH_TEMPLATE_BUTTON_TYPE = {
    QUICK_REPLY: "QUICK_REPLY",
    CALL_TO_ACTION: "CALL_TO_ACTION"
};
export const CALL_TO_ACTION_TYPES = {
    URL: "URL",
    PHONE_NUMBER: "PHONE_NUMBER"
};
export const TEMPLATE_PARTS = {
    HEADER: "HEADER",
    BODY: "BODY",
    FOOTER: "FOOTER",
    BUTTONS: "BUTTONS",
};
export const DB_COLLECTIONS = {
    USERS: 'users',
    USERS_STATUS: 'users-status',
    SETTINGS: 'settings',
};
export const FEATURE = {
    CSV_REPORT: 'CSVReport',
    FULLTEXT_SEARCH: 'fulltext_search',
};
export var AGENT_TYPES;
(function (AGENT_TYPES) {
    AGENT_TYPES[AGENT_TYPES["ADMIN"] = 1] = "ADMIN";
    AGENT_TYPES[AGENT_TYPES["SIMPLE"] = 2] = "SIMPLE";
})(AGENT_TYPES || (AGENT_TYPES = {}));
export const LANGUAGES_TYPES = {
    HE: 'he',
    EN: 'en',
    AR: 'ar',
    RU: 'ru',
    IT: 'it',
};
export const RESOLVE_HOURS = {
    1: 1,
    3: 3,
    6: 6,
    12: 12,
    24: 24,
    36: 36,
    48: 48,
    72: 72,
    CUSTOM: 48,
};
export const CRM_IDS = {
    POWERLINK: "powerlink",
    GOOGLESHEET: "googlesheet",
    LEADIM: "leadim",
    SALESFORCE: "salesforce",
    NOVIDEA: "novidea",
    MONDAY: "monday",
};
export const WEBSITE_LINK = "https://texterchat.com/";
export const SUPPORT_LINK = "https://wa.me/972586640430";
export const YOUTUBE_TUTORIALS_URLS = {
    PLAYLIST: "https://www.youtube.com/playlist?list=PLvLHZApxkds7pOwib0okr4KNc4CkyQtn7",
    NEW_TEMPLATE: 'https://www.youtube.com/watch?v=CkXIW54Aexg',
    RICH_TEMPLATE_PARAMETERS: "https://www.youtube.com/watch?v=2RBFqjRqimU",
    SENDING_BULK_MESSAGES: 'https://www.youtube.com/watch?v=b3BqHyreW8g',
    INBOX_TUTORIAL: "https://www.youtube.com/watch?v=9reeLFgxcrw",
    ADMIN_TUTORIAL: "https://youtu.be/lPEqwvK8Pm0",
};
export const VERIFY_BADGE_URL = 'https://docs.google.com/forms/d/e/1FAIpQLScYJJQ2mf-tXwP0VQ-38qwRrTY1yoGz7Vq-i8s5OFrd04hbhw/viewform';
export const MESSAGE_TEMPLATES_BUSINESS_URL = 'https://business.facebook.com/wa/manage/message-templates/';
export const MOBILE_APP_URLS = {
    IOS: 'https://apps.apple.com/app/texter-chat/id1570892710',
    ANDROID: 'https://texterchat.com/texterchat.apk',
};
export const SEND_APP_LINKS_URL = 'https://hook.eu1.make.com/jmjpjw2q3esxskro5tmplwpwccfwhu2v';
export const TEXTERCHAT_HOMEPAGE_URL = 'https://texterchat.com/';
export const SEARCH_THROTTLE = 500;
export const SEARCH_MIN_QUERY = 2;
export const PROXY_FRONTEND_URL = process.env.REACT_APP_PROXY_FRONTEND_URL;
export const SLA_TIMEPICKER_FORMAT = "HH:mm";
export const COMPOSE_SESSION_STATE_KEY = 'chatInput';
export const AGENTS_DEPARTMENTS_COLORS = [
    '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8'
];
export const SESSION_OVER_TEMPLATE_SEND_COST = 0.18;
/**
 * URL of the image used as example for template image header
 * if none provided instead.
 * @todo Make a better solution, this were added in urgency
 */
export const DEFAULT_EXAMPLE_TEMPLATE_IMG_URL = 'https://i.imgur.com/gtkrswP.jpeg';
