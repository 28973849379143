import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import './ContactFilters.css';
import { withTranslation } from 'react-i18next';
import DepartmentsFilter from '../DepFilter';
import { observer } from 'mobx-react';
import { MyInput } from '../../../../Base/Form';
class ContactFilters extends Component {
    render() {
        const { setSearchFilter, setDepartmentFilter, text, currentDepartmentId, t } = this.props;
        return (React.createElement("div", { className: "contact-filter-header" },
            React.createElement("div", { className: "department-filter-wrapper" },
                React.createElement(MyInput, { type: "search", size: "large", className: "contact-filter-input", placeholder: t('searchChat'), value: text, onChangeHandler: setSearchFilter, prefix: React.createElement(SearchOutlined, { className: "contact-filter-search-icon" }) })),
            React.createElement("div", { className: "department-filter-wrapper" },
                React.createElement(DepartmentsFilter, { currentDepartmentId: currentDepartmentId, setDepartmentFilter: setDepartmentFilter, size: "large" }))));
    }
}
export default withTranslation()(observer(ContactFilters));
