import React from 'react';
import classnames from 'classnames';
import { Divider, Empty, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import TextTransition from './TextTransition';
import ResolvedChart from './ResolvedChart';
import SLAGauge from './SLAGauge';
import { formatAsPercent } from '../../BulkReportsDrawer/helpers';
import s from '../DashboardDrawer.module.scss';
export var BoxValueTypes;
(function (BoxValueTypes) {
    BoxValueTypes[BoxValueTypes["SIMPLE"] = 0] = "SIMPLE";
    BoxValueTypes[BoxValueTypes["OVER"] = 1] = "OVER";
    BoxValueTypes[BoxValueTypes["OVER_EXTRA"] = 2] = "OVER_EXTRA";
    BoxValueTypes[BoxValueTypes["CHART"] = 3] = "CHART";
    BoxValueTypes[BoxValueTypes["CHART_SMALL"] = 4] = "CHART_SMALL";
    BoxValueTypes[BoxValueTypes["GAUGE"] = 5] = "GAUGE";
})(BoxValueTypes || (BoxValueTypes = {}));
const Box = ({ title, icon, loading, inverted, data, color }) => {
    const { t } = useTranslation();
    const valueOrPlaceholder = (value) => {
        if (value === undefined || value === null) {
            return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: t('noData'), className: classnames(s['empty-placeholder'], {
                    [s['empty-placeholder--inverted']]: inverted,
                }) }));
        }
        return value.toString();
    };
    if (loading) {
        return (React.createElement("div", { className: s['box__container'] },
            React.createElement(Skeleton, { active: true })));
    }
    return (React.createElement("div", { className: s['box__container'], style: { borderColor: color } },
        React.createElement("div", { className: s['box__title'] },
            React.createElement("div", { className: classnames(s['box__icon'], { [s['box__icon--inverted']]: inverted }) }, icon),
            React.createElement("div", { className: classnames(s['box__text'], { [s['box__text--inverted']]: inverted }) }, title)),
        React.createElement("div", { className: classnames(s['box__value'], {
                [s['box__value--compact']]: (data === null || data === void 0 ? void 0 : data.type) === BoxValueTypes.GAUGE && !inverted,
            }) },
            (data === null || data === void 0 ? void 0 : data.type) === BoxValueTypes.SIMPLE && (React.createElement("div", { className: classnames(s['box__value--simple'], {
                    [s['box__value--simple--inverted']]: inverted,
                    [(data === null || data === void 0 ? void 0 : data.additionalClassname) || '']: data === null || data === void 0 ? void 0 : data.additionalClassname,
                }) },
                React.createElement(TextTransition, { text: valueOrPlaceholder(data.value) }))),
            [BoxValueTypes.OVER, BoxValueTypes.OVER_EXTRA].includes(data === null || data === void 0 ? void 0 : data.type) && (React.createElement("div", { className: classnames(s['box__value--over'], {
                    [s['box__value--over--inverted']]: inverted,
                }) },
                React.createElement("div", { className: classnames(s['box__value__contentContainer'], {
                        [s['box__value__contentContainer--inverted']]: inverted,
                    }) },
                    React.createElement("div", { className: classnames(s['over__title'], {
                            [s['over__title--inverted']]: inverted,
                            [s['over__title--time']]: inverted && (data === null || data === void 0 ? void 0 : data.type) === BoxValueTypes.OVER,
                            [s['over__title--noData']]: (data === null || data === void 0 ? void 0 : data.value[0]) === undefined,
                        }) },
                        React.createElement(TextTransition, { text: valueOrPlaceholder(data === null || data === void 0 ? void 0 : data.value[0]) })),
                    React.createElement("div", { className: classnames(s['over-under__subtitle'], s['over-under__subtitle--up'], {
                            [s['over-under__subtitle--inverted']]: inverted,
                        }) }, (data === null || data === void 0 ? void 0 : data.type) === BoxValueTypes.OVER ? 'Avg.' : 'Total')),
                React.createElement(Divider, { className: classnames({
                        [s['divider--small']]: (data === null || data === void 0 ? void 0 : data.value[0]) === undefined && !inverted,
                    }) }),
                React.createElement("div", { className: classnames(s['box__value__contentContainer'], {
                        [s['box__value__contentContainer--inverted']]: inverted,
                    }) },
                    React.createElement("div", { className: classnames(s['under__title'], {
                            [s['under__title--inverted']]: inverted,
                            [s['under__title--noData']]: (data === null || data === void 0 ? void 0 : data.value[1]) === undefined,
                        }) }, data === null || data === void 0 ? void 0 :
                        data.extraIcon,
                        React.createElement(TextTransition, { text: valueOrPlaceholder(data === null || data === void 0 ? void 0 : data.value[1]) })),
                    React.createElement("div", { className: classnames(s['over-under__subtitle'], {
                            [s['over-under__subtitle--inverted']]: inverted,
                        }) }, (data === null || data === void 0 ? void 0 : data.type) === BoxValueTypes.OVER ? 'Max' : 'W/Bot')))),
            [BoxValueTypes.CHART, BoxValueTypes.CHART_SMALL].includes(data === null || data === void 0 ? void 0 : data.type) && (React.createElement("div", { className: classnames(s['box__value--chart'], {
                    [s['box__value--chart--compact']]: BoxValueTypes.CHART_SMALL === (data === null || data === void 0 ? void 0 : data.type),
                }) },
                React.createElement(ResolvedChart, { resolvedData: [
                        {
                            title: 'Resolved',
                            count: data === null || data === void 0 ? void 0 : data.value.agent,
                        },
                        {
                            title: 'Rest',
                            count: (data === null || data === void 0 ? void 0 : data.value.total) - (data === null || data === void 0 ? void 0 : data.value.agent) - (data === null || data === void 0 ? void 0 : data.value.bot),
                        },
                        ...((data === null || data === void 0 ? void 0 : data.value.bot) !== null ? [{ title: 'By Bot', count: data === null || data === void 0 ? void 0 : data.value.bot }] : []),
                    ], extraData: data === null || data === void 0 ? void 0 : data.value, inverted: !!inverted, className: s['box__value--chart--grid'] }),
                BoxValueTypes.CHART_SMALL === (data === null || data === void 0 ? void 0 : data.type) && (React.createElement("div", { className: classnames(s['box__value--chart__small'], {
                        [s['box__value--chart__small__noBot']]: (data === null || data === void 0 ? void 0 : data.value.bot) === null,
                    }) }, (data === null || data === void 0 ? void 0 : data.value.agent) !== undefined ? (React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        "Total ",
                        (data === null || data === void 0 ? void 0 : data.value.agent) + ((data === null || data === void 0 ? void 0 : data.value.bot) || 0),
                        "/"),
                    React.createElement("div", { className: s['box__value--chart--smallLabel'] }, data === null || data === void 0 ? void 0 : data.value.total))) : (valueOrPlaceholder(null)))),
                (data === null || data === void 0 ? void 0 : data.value.bot) !== null && (React.createElement("div", { className: classnames(s['box__value__contentContainer'], s['box__value__contentContainer--withIcon'], {
                        [s['box__value__contentContainer--inverted']]: inverted,
                    }) },
                    React.createElement("div", { className: classnames(s['under__title'], {
                            [s['under__title--inverted']]: inverted,
                        }) }, data === null || data === void 0 ? void 0 :
                        data.extraIcon,
                        React.createElement(TextTransition, { text: valueOrPlaceholder(data === null || data === void 0 ? void 0 : data.value.bot) })),
                    React.createElement("div", { className: classnames(s['over-under__subtitle'], {
                            [s['over-under__subtitle--inverted']]: inverted,
                        }) },
                        "By Bot (",
                        formatAsPercent(((data === null || data === void 0 ? void 0 : data.value.bot) / (data === null || data === void 0 ? void 0 : data.value.total)) * 100),
                        ")"))))),
            (data === null || data === void 0 ? void 0 : data.type) === BoxValueTypes.GAUGE && (React.createElement("div", { className: s['box__value--gauge'] },
                React.createElement(SLAGauge, { gaugeData: {
                        percent: data === null || data === void 0 ? void 0 : data.value.percent,
                        inverted: !!inverted,
                    } }),
                React.createElement("div", { className: classnames(s['box__value--gauge--content'], {
                        [s['box__value--gauge--content--inverted']]: inverted,
                    }) },
                    React.createElement("div", { className: classnames(s['box__value--gauge__value'], {
                            [s['box__value--gauge__value--inverted']]: inverted,
                        }) }, (data === null || data === void 0 ? void 0 : data.value.time) && (React.createElement(TextTransition, { text: valueOrPlaceholder(formatAsPercent((data === null || data === void 0 ? void 0 : data.value.percent) * 100)) })
                    // <CaretUp /> TODO: Caret up/down was idea before
                    )),
                    React.createElement("div", { className: classnames(s['box__value--gauge__valueSmall'], {
                            [s['box__value--gauge__valueSmall--inverted']]: inverted,
                        }) },
                        React.createElement(TextTransition, { text: valueOrPlaceholder(data === null || data === void 0 ? void 0 : data.value.time) }),
                        ' ',
                        (data === null || data === void 0 ? void 0 : data.value.time) ? 'Min' : '')))))));
};
export default Box;
