import React from "react";
import { observer } from "mobx-react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";
import moment from "moment";
import classnames from "classnames";
import { Tooltip } from "antd";
import { useStore } from "../../../../store/rootStore";
import TemplateTitle from "../TemplateTitle/TemplateTitle";
import AdvancedSettings from "../../../Chat/QuickReplies/TemplateGenerator/AddOrEditTemplate/AdvancedSettings";
import { CHAT_STATUS } from "@whatsper/texterchat-common";
import useCheckIfMobile from "../../../../helpers/hooks/useCheckIfMobile";
import { DESKTOP_WIDTH } from "../../../../constants";
import { getDirection } from "../../../../helpers";
import s from "./SecondPage.module.scss";
const SecondPage = () => {
    var _a, _b;
    const { wizardStore, templateMessagesStore } = useStore();
    const isLessThanDesktop = useCheckIfMobile(DESKTOP_WIDTH);
    const assignedTo = templateMessagesStore.templateChatStatus === CHAT_STATUS.ASSIGNED;
    const showEllipsis = assignedTo && isLessThanDesktop;
    const resolveDate = () => {
        const date = moment().add(3, "days");
        return `${date.format("D/MM/YYYY")} at ${date.format("HH:mm")}`;
    };
    return (React.createElement("div", { className: classnames(s.secondPageWrapper, {
            [s.rtl]: getDirection() === 'rtl',
        }) },
        React.createElement(TemplateTitle, { title: ((_a = wizardStore.chosenTemplate) === null || _a === void 0 ? void 0 : _a.title) || '', name: ((_b = wizardStore.chosenTemplate) === null || _b === void 0 ? void 0 : _b.name) || '', isRtl: getDirection() === 'rtl' }),
        React.createElement("div", { className: classnames(s.csvWizardAdvancedDescriptionBlock, {
                [s.rtl]: getDirection() === 'rtl',
            }) },
            React.createElement(ExclamationCircleOutlined, { className: s['wizard-info-circle'] }),
            React.createElement(Tooltip, { title: showEllipsis ? (React.createElement(Trans, { i18nKey: "templateAdvancedSettingsExplanationP2", values: { date: resolveDate() } })) : undefined },
                React.createElement("p", { className: classnames(s.bulkWizard, {
                        [s.ellipsis]: showEllipsis,
                    }) },
                    React.createElement(Trans, { i18nKey: "templateAdvancedSettingsExplanationP2", values: { date: resolveDate() } })))),
        React.createElement("div", { className: s.bulkAdvandesSettingsWrapper },
            React.createElement(AdvancedSettings, { canAttachFile: true, canChangeMode: false }))));
};
export default observer(SecondPage);
