var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import LoadingScreen from '../../../LoadingScreen';
import ContactFilters from './ContactFilters';
import ContactList from './ContactList';
import { FILTER_DEPARTMENTS } from '../../../../constants';
import { withStore } from '../../../../store/rootStore';
import styles from './ContactListSelector.module.scss';
class ContactListSelector extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                currentDepartmentId: null,
                text: '',
                chats: [],
                loading: false,
            }
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.loadChats();
            }
        });
        Object.defineProperty(this, "setDepartmentFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (departmentId) => {
                this.setState({ currentDepartmentId: departmentId }, () => this.loadChats());
            }
        });
        Object.defineProperty(this, "setSearchFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState({ text }, () => this.loadChats());
            }
        });
        Object.defineProperty(this, "isChatActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chat) => {
                if (chat.last_message_timestamp) {
                    return Date.now() - chat.last_message_timestamp <= 24 * 60 * 60 * 1000 && chat.channel !== "messenger";
                }
                else
                    return false;
            }
        });
        Object.defineProperty(this, "addToSelectedList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => {
                const { selectedContacts, listUpdated, multichoice } = this.props;
                if (!multichoice && !selectedContacts.includes(chatId)) {
                    listUpdated([chatId]);
                }
                else if (!multichoice && selectedContacts.includes(chatId)) {
                    listUpdated([]);
                }
                else if (multichoice && !selectedContacts.includes(chatId)) {
                    listUpdated([...selectedContacts, chatId]);
                }
                else if (multichoice && selectedContacts.includes(chatId)) {
                    const newList = selectedContacts.filter((item) => item !== chatId);
                    listUpdated(newList);
                }
            }
        });
        Object.defineProperty(this, "renderList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chats, loading } = this.state;
                const { selectedContacts } = this.props;
                if (loading) {
                    return (React.createElement(LoadingScreen, null));
                }
                return (React.createElement(ContactList, { chats: chats, selectedContacts: selectedContacts, addToSelectedList: this.addToSelectedList }));
            }
        });
        Object.defineProperty(this, "loadChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                const { text, currentDepartmentId } = this.state;
                this.props.store.chatsStore.fetchChats({
                    search: text,
                    department: currentDepartmentId && currentDepartmentId !== FILTER_DEPARTMENTS.ALL_DEPARTMENTS ? currentDepartmentId : undefined,
                })
                    .then((result) => {
                    const chats = result.chats;
                    if (this.props.onlyActiveChats) {
                        this.setState({ chats: chats.filter(this.isChatActive) });
                    }
                    else {
                        this.setState({ chats: chats });
                    }
                })
                    .catch((error) => {
                    console.error('Error loading chats', error);
                })
                    .finally(() => {
                    this.setState({ loading: false });
                });
            })
        });
    }
    render() {
        const { currentDepartmentId, text } = this.state;
        return (React.createElement("div", { className: styles.wrap },
            React.createElement(ContactFilters, { currentDepartmentId: currentDepartmentId, text: text, setSearchFilter: this.setSearchFilter, setDepartmentFilter: this.setDepartmentFilter }),
            this.renderList()));
    }
}
export default withStore(observer(ContactListSelector));
