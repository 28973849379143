var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from "react";
import { Button, Steps, message } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Trans, withTranslation } from "react-i18next";
import moment from "moment";
import { CHAT_STATUS, TEMPLATE_HEADER_TYPE } from "@whatsper/texterchat-common";
import { WIZARD_STEPS } from "../../../constants";
import { withStore } from "../../../store/rootStore";
import { getDirection } from "../../../helpers";
import { updateLastSent } from "../../Templates/TemplateListFilterable/helpers";
import "./WizardBottom.scss";
class WizardBottom extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
            }
        });
        Object.defineProperty(this, "cancelRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "changeStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (current) => {
                const { selectedAccountId } = this.props;
                const { wizardStore } = this.props.store;
                if ((current === WIZARD_STEPS.ACCOUNT_STEP && !selectedAccountId) ||
                    (current >= WIZARD_STEPS.FIRST_STEP && !wizardStore.chosenTemplate) ||
                    (current === (wizardStore.templateHasFile ? WIZARD_STEPS.FIFTH_STEP : WIZARD_STEPS.FOURTH_STEP) && !wizardStore.uploadedFile || wizardStore.uploadedFile && !wizardStore.uploadedFile.id)) {
                    return;
                }
                else {
                    if (current > wizardStore.wizardStep && this.isContinueButtonDisabled()) {
                        return;
                    }
                    wizardStore.setWizardStep(current);
                }
            }
        });
        Object.defineProperty(this, "cancelHandle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.closeModal();
                this.props.store.wizardStore.resetStore();
            }
        });
        Object.defineProperty(this, "backHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.cancelRef) === null || _a === void 0 ? void 0 : _a.blur();
                if (this.props.store.wizardStore.wizardStep === WIZARD_STEPS.ACCOUNT_STEP) {
                    this.cancelHandle();
                }
                else {
                    this.props.store.wizardStore.changeWizardStep(-1);
                }
            }
        });
        Object.defineProperty(this, "forwardHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                const { selectedAccountId, t } = this.props;
                const { wizardStore } = this.props.store;
                if (!wizardStore.chosenTemplate) {
                    message.error(t('templateSendError'), 5);
                    return;
                }
                ;
                const templateName = wizardStore.chosenTemplate.name;
                if (wizardStore.wizardStep === (wizardStore.templateHasFile ? WIZARD_STEPS.FIFTH_STEP : WIZARD_STEPS.FOURTH_STEP) && selectedAccountId) {
                    this.setState({ loading: true });
                    const isScheduled = wizardStore.scheduled && wizardStore.scheduledOn;
                    const successMessage = (React.createElement(Trans, { i18nKey: "templateSentSuccess", values: {
                            title: (_a = wizardStore.chosenTemplate) === null || _a === void 0 ? void 0 : _a.title,
                            clientsAmount: wizardStore.clientsAmount,
                        }, components: { b: React.createElement("strong", null) } }));
                    const successMessageScheduled = (React.createElement(Trans, { i18nKey: "templateScheduleSentSuccess", values: {
                            title: (_b = wizardStore.chosenTemplate) === null || _b === void 0 ? void 0 : _b.title,
                            clientsAmount: wizardStore.clientsAmount,
                            date: wizardStore.scheduledOn ? moment(wizardStore.scheduledOn * 1000).format('D MMM YYYY, ddd, h:mma') : undefined,
                        } }));
                    yield wizardStore
                        .sendCsvTemplates(selectedAccountId.id)
                        .then(() => {
                        message.success(isScheduled ? successMessageScheduled : successMessage);
                        updateLastSent(templateName, selectedAccountId.id);
                        this.cancelHandle();
                    })
                        .catch((err) => {
                        message.error(React.createElement(Trans, { i18nKey: "templateSentError", values: { message: err.message } }), 5);
                    })
                        .finally(() => {
                        this.setState({ loading: false });
                    });
                }
                else {
                    wizardStore.changeWizardStep(1);
                }
            })
        });
        Object.defineProperty(this, "isContinueButtonDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { wizardStep, termsAgreement, chosenTemplate, uploadedFile, richHeader, templateHasFile } = this.props.store.wizardStore;
                const { responsibleAgent, responsibleDepartment, templateChatStatus } = this.props.store.templateMessagesStore;
                const isNotAssignedTo = templateChatStatus === CHAT_STATUS.ASSIGNED && !(responsibleAgent || responsibleDepartment);
                if (wizardStep === WIZARD_STEPS.ACCOUNT_STEP) {
                    return true;
                }
                else if (wizardStep === WIZARD_STEPS.FIFTH_STEP && !chosenTemplate) {
                    return true;
                }
                else if (wizardStep === (templateHasFile ? WIZARD_STEPS.THIRD_STEP : WIZARD_STEPS.SECOND_STEP) && isNotAssignedTo) {
                    return true;
                }
                else if (wizardStep === (templateHasFile ? WIZARD_STEPS.FOURTH_STEP : WIZARD_STEPS.THIRD_STEP) && uploadedFile && !uploadedFile.id && uploadedFile.id !== 0) {
                    return true;
                }
                else if (wizardStep === (templateHasFile ? WIZARD_STEPS.FIFTH_STEP : WIZARD_STEPS.FOURTH_STEP) && !termsAgreement) {
                    return true;
                }
                else if (wizardStep === WIZARD_STEPS.FIRST_STEP) {
                    return true;
                }
                else if (wizardStep === WIZARD_STEPS.SECOND_STEP &&
                    ((((_a = chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.header) === null || _a === void 0 ? void 0 : _a.type) && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.VIDEO].includes(chosenTemplate.header.type) &&
                        chosenTemplate.header.type !== (richHeader === null || richHeader === void 0 ? void 0 : richHeader.type)) ||
                        (richHeader === null || richHeader === void 0 ? void 0 : richHeader.error))) {
                    return true;
                }
                else {
                    return false;
                }
            }
        });
        Object.defineProperty(this, "renderButtonText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { wizardStep, scheduled, scheduledOn, templateHasFile } = this.props.store.wizardStore;
                if (wizardStep !== (templateHasFile ? WIZARD_STEPS.FIFTH_STEP : WIZARD_STEPS.FOURTH_STEP)) {
                    return React.createElement(Trans, { i18nKey: "next" });
                }
                if (!scheduled) {
                    return React.createElement(Trans, { i18nKey: "send" });
                }
                if (scheduledOn) {
                    const date = moment(scheduledOn * 1000);
                    const datetime = date.format("HH:mm DD.MM");
                    return React.createElement(Trans, { i18nKey: "scheduleSendOn", values: { datetime } });
                }
                return null;
            }
        });
    }
    render() {
        const { wizardStep, templateHasFile } = this.props.store.wizardStore;
        const { loading } = this.state;
        return (React.createElement("div", { className: classNames("wizard-bottom-wrapper", {
                "rtl": getDirection() === "rtl",
            }) },
            React.createElement(Button, { className: classNames("big", "white-button", "wizard-cancel-button"), ref: (cancelRef) => {
                    this.cancelRef = cancelRef;
                }, onClick: this.backHandler, disabled: loading }, wizardStep === WIZARD_STEPS.ACCOUNT_STEP ? React.createElement(Trans, { i18nKey: "cancel" }) : React.createElement(Trans, { i18nKey: "prev" })),
            React.createElement(Steps, { className: "wizardSteps", current: wizardStep, onChange: this.changeStep, items: Array(templateHasFile ? 6 : 5).fill({ disabled: loading }) }),
            React.createElement(Button, { disabled: loading || this.isContinueButtonDisabled(), className: classNames("big", "big-green-button", "wizard-next-button"), type: "primary", onClick: this.forwardHandler, loading: loading }, this.renderButtonText())));
    }
}
export default withTranslation()(withStore(observer(WizardBottom)));
