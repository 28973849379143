import React, { Component } from 'react';
import classnames from 'classnames';
import ChatsListItem from '../../../../Chat/ChatsListItem';
import { ReactComponent as SingleCheck } from '../../../../../assets/icons/chatStatuses/singleCheck.svg';
import Icon from '@ant-design/icons';
import styles from './ContactListItem.module.scss';
class ContactListItem extends Component {
    render() {
        const { chat, selected, addToSelectedList } = this.props;
        return (React.createElement("div", { className: classnames(styles.contactListItem, { [styles.contactListItemSelected]: selected }), onClick: addToSelectedList },
            React.createElement(ChatsListItem, { chat: chat, search: '', divider: false, showUnreadCount: false, className: styles.chatItem }),
            React.createElement("div", { className: styles.contactChosenWrapper }, selected &&
                React.createElement(Icon, { className: styles.contactItemChosenCheck, component: SingleCheck }))));
    }
}
export default ContactListItem;
