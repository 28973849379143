import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Button } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";
import leadImLogo from "../../assets/crmLogos/leadImLogo.png";
import googleDriveLogo from "../../assets/crmLogos/googleDriveLogo.png";
import powerlinkLogo from "../../assets/crmLogos/powerlinkLogo.svg";
import salesforceLogo from "../../assets/crmLogos/salesforceLogo.svg";
import mondayLogo from "../../assets/crmLogos/mondayLogo.png";
import crmLogoDefault from "../../assets/crmLogos/crmLogoDefault.png";
import { ReactComponent as ExportLogo } from "../../assets/icons/arrows/export/exportLogo.svg";
import NoActiveChatSelected from "../Chat/NoActiveChatSelected";
import { CRM_IDS } from "../../constants";
import ClientInfo from "./Components/ClientInfo";
import ErrorBlock from "./Components/ErrorBlock";
import NoContact from "./Components/NoContact";
import LoadingIcon from "../Chat/common/LoadingIcon";
import { withStore } from '../../store/rootStore';
import classNames from 'classnames';
import LinkedChats from './Components/LinkedChats';
import { MySelect } from '../Base/Form';
import ChatNotes from '../Chat/ChatNotes';
import s from './CRM.module.scss';
/**
 * THIS COMPONENT REQUIRES FULL REFACTORING!!!
 */
class CRM extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                noteText: '',
                addNoteFieldOpen: true,
            }
        });
        Object.defineProperty(this, "handleStatusChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.props.store.crmStore.changeStatus(value);
            }
        });
        Object.defineProperty(this, "handleOpenLink", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.props.store.crmStore.crmDataLink) {
                    const win = window.open(this.props.store.crmStore.crmDataLink, 'crm');
                    win === null || win === void 0 ? void 0 : win.focus();
                }
            }
        });
        Object.defineProperty(this, "getLogo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { crmStore } = this.props.store;
                const crmId = crmStore.crmId;
                if (crmId === CRM_IDS.POWERLINK) {
                    return powerlinkLogo;
                }
                else if (crmId === CRM_IDS.GOOGLESHEET) {
                    return googleDriveLogo;
                }
                else if (crmId === CRM_IDS.LEADIM) {
                    return leadImLogo;
                }
                else if (crmId === CRM_IDS.MONDAY) {
                    return mondayLogo;
                }
                else if (!!crmId && crmId.indexOf(CRM_IDS.SALESFORCE) !== -1) {
                    return salesforceLogo;
                }
                else if (!!crmId && crmId.indexOf(CRM_IDS.NOVIDEA) !== -1) {
                    return salesforceLogo;
                }
                else
                    return crmLogoDefault;
            }
        });
        Object.defineProperty(this, "getTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { crmStore } = this.props.store;
                const crmId = crmStore.crmId;
                if (crmId === CRM_IDS.POWERLINK) {
                    return "Powerlink";
                }
                else if (crmId === CRM_IDS.GOOGLESHEET) {
                    return "Google Drive";
                }
                else if (crmId === CRM_IDS.LEADIM) {
                    return "Lead.im";
                }
                else if (crmId === CRM_IDS.MONDAY) {
                    return "Monday";
                }
                else if (!!crmId && crmId.indexOf(CRM_IDS.SALESFORCE) !== -1) {
                    return "Salesforce";
                }
                else if (!!crmId && crmId.indexOf(CRM_IDS.NOVIDEA) !== -1) {
                    return "Novidea";
                }
                else
                    return (!!crmId ? crmId.charAt(0).toUpperCase() + crmId.slice(1) : "");
            }
        });
        Object.defineProperty(this, "contactUs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const win = window.open("https://texterchat.com/", 'crm');
                win === null || win === void 0 ? void 0 : win.focus();
            }
        });
        Object.defineProperty(this, "renderActiveChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { crmStore, activeChatStore } = this.props.store;
                return (React.createElement("div", { className: classNames(s["crm-wrapper"], {
                        [s["crm-noData"]]: !crmStore.crmData,
                    }) },
                    React.createElement("div", { className: s["crm-link-title-block"] },
                        React.createElement("div", { className: s["crm-link-title"] },
                            React.createElement("div", { className: s["crm-logo-circle"] },
                                React.createElement("img", { className: s["crm-logo-circle-image"], src: this.getLogo() })),
                            React.createElement("div", { className: s["crm-title-text"] }, this.getTitle())),
                        React.createElement(Button, { disabled: !crmStore.crmDataLink, shape: "circle", className: s["link-button-circle"], onClick: this.handleOpenLink },
                            React.createElement(ExportLogo, null))),
                    (!crmStore.crmDataLoading && crmStore.crmData && crmStore.crmData.success) &&
                        React.createElement("div", { className: s["content-wrapper"] },
                            React.createElement(ClientInfo, { name: crmStore.crmDataName, phone: crmStore.crmDataPhone, id: crmStore.crmDataID }),
                            React.createElement("div", { className: s["user-status"] },
                                React.createElement("div", { className: s["client-info-subheader"] },
                                    React.createElement(Trans, { i18nKey: "status" }),
                                    ":"),
                                crmStore.crmStatusesLoading && React.createElement(LoadingOutlined, { style: { fontSize: 24 } }),
                                !crmStore.crmStatusesLoading &&
                                    React.createElement(MySelect, { className: s['select-active-status'], value: crmStore.crmStatus, style: { width: 120 }, onChange: this.handleStatusChange, disabled: !activeChatStore.chat || crmStore.crmStatuses.length === 0, virtual: false, size: "middle", options: (_a = crmStore.crmStatuses) === null || _a === void 0 ? void 0 : _a.map(status => ({
                                            label: status.name,
                                            value: status.value,
                                        })) }))),
                    activeChatStore.chat && crmStore.crmDataLoading &&
                        React.createElement("div", { className: s["crm-fail-text-wrapper"] },
                            React.createElement(LoadingIcon, { additionalStyles: s["crm-preloader"] })),
                    (!(crmStore.crmData && crmStore.crmData.success) && !crmStore.crmDataLoading) &&
                        React.createElement("div", { className: s["no-contact-wrapper"] },
                            React.createElement(NoContact, null)),
                    React.createElement(LinkedChats, null),
                    React.createElement(ChatNotes, null)));
            }
        });
    }
    render() {
        const { crmStore, activeChatStore } = this.props.store;
        return (React.createElement(React.Fragment, null,
            (activeChatStore.chat && !crmStore.crmError) && this.renderActiveChat(),
            !crmStore.crmError && !activeChatStore.chat &&
                React.createElement("div", { className: s["crm-blocker-wrapper"] },
                    React.createElement(NoActiveChatSelected, { bottomBlock: false })),
            crmStore.crmError && !crmStore.crmDataLoading &&
                React.createElement(ErrorBlock, null)));
    }
}
export default withStore(observer(CRM));
