import React from 'react';
import { observer } from 'mobx-react';
import './RightBar.css';
import CRM from '../../CRM';
import LogOutSection from './atoms/LogOutSection';
import ChatStatusButtons from './atoms/ChatStatusButtons';
import { useStore } from '../../../store/rootStore';
import ErrorBoundary from '../../ErrorBoundary';
const RightBar = () => {
    const store = useStore();
    const { userDetails } = store.loginStore;
    return (React.createElement("div", { className: "right-bar" },
        React.createElement(LogOutSection, { name: userDetails === null || userDetails === void 0 ? void 0 : userDetails.displayName }),
        React.createElement(ErrorBoundary, null,
            React.createElement(CRM, null)),
        React.createElement(ChatStatusButtons, { visible: true })));
};
export default observer(RightBar);
