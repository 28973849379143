import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ContactListItem from '../ContactListItem';
import styles from './ContactList.module.scss';
class ContactList extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "addToList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => {
                this.props.addToSelectedList(chatId);
            }
        });
    }
    render() {
        const { chats, selectedContacts } = this.props;
        return (React.createElement("div", { className: styles.contactList }, chats.map((chat) => (React.createElement(ContactListItem, { key: chat._id, chat: chat, addToSelectedList: () => this.addToList(chat._id), selected: selectedContacts && selectedContacts.includes(chat._id) })))));
    }
}
export default observer(ContactList);
