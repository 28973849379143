import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { withStore } from '../../../store/rootStore';
import Icon, { FileFilled } from '@ant-design/icons';
import { MEDIA_TYPE } from '@whatsper/texterchat-common';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { has } from 'lodash';
import { ReactComponent as QuickRepliesCross } from '../../../assets/messageStatuses/closeCross.svg';
import LoadingIcon from '../../Chat/common/LoadingIcon';
import Compose from '../Compose';
import s from './FilePreview.module.scss';
const SUPPORTED_FORMATS = [MEDIA_TYPE.IMAGE, MEDIA_TYPE.VIDEO, MEDIA_TYPE.AUDIO];
const FilePreview = ({ store, activeChat, composeProps }) => {
    var _a, _b, _c, _d;
    const innerRef = useRef(null);
    const [pageHeight, setPageHeight] = useState(0);
    const { t } = useTranslation();
    const { filePreview, toggleFilePreview, fileData, theme, activeReplyMessage } = store.activeChatStore;
    const adapter = activeChat ? store.channels.getAccountAdapter({
        id: activeChat.channelInfo.accountId,
        name: activeChat.channelInfo.name,
    }) : null;
    const mediaLimits = adapter === null || adapter === void 0 ? void 0 : adapter.messageLimits.media;
    const skipCaption = has(mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits[(fileData === null || fileData === void 0 ? void 0 : fileData.type) || ''], 'caption')
        ? !((_a = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits[(fileData === null || fileData === void 0 ? void 0 : fileData.type) || '']) === null || _a === void 0 ? void 0 : _a.caption)
        : !((_b = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document) === null || _b === void 0 ? void 0 : _b.caption);
    const disabledMessage = skipCaption ? 'captionNotAvailable' : null;
    const captionMaxLength = (_c = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits[(fileData === null || fileData === void 0 ? void 0 : fileData.type) || '']) === null || _c === void 0 ? void 0 : _c.captionMaxLength;
    const composeButtonRef = useRef(null);
    const onKeyDown = (e) => {
        var _a;
        if (!filePreview)
            return;
        if (e.key === 'Escape') {
            toggleFilePreview(false);
        }
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            (_a = composeButtonRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [filePreview]);
    if (!filePreview)
        return null;
    const ext = (_d = fileData === null || fileData === void 0 ? void 0 : fileData.file.name.split('.')) === null || _d === void 0 ? void 0 : _d[1];
    const sendFile = (message) => {
        const caption = skipCaption ? '' : message;
        const messageQuotedId = activeReplyMessage === null || activeReplyMessage === void 0 ? void 0 : activeReplyMessage.channelInfo.id;
        if (fileData) {
            store.activeChatStore.sendMediaMessage(fileData.file, fileData.type, fileData.file.name, caption, messageQuotedId);
            composeProps.onChange('');
            toggleFilePreview(false);
        }
    };
    const onPDFLoadSuccess = () => {
        var _a;
        const containerHeight = (((_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0) * 0.9 - 12;
        setPageHeight(containerHeight);
    };
    const onPageLoadSuccess = () => {
        const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
        const annotationsLayers = document.querySelectorAll('.react-pdf__Page__annotations');
        [...textLayers, ...annotationsLayers].forEach((layer) => {
            const { style } = layer;
            style.display = 'none';
        });
    };
    const hasPreview = fileData && SUPPORTED_FORMATS.includes(fileData.type) || ext === 'pdf';
    const size = fileData ? fileData.file.size / 1000 > 1000
        ? `${(fileData.file.size / 1000000).toFixed(2)} MB`
        : `${(fileData.file.size / 1000).toFixed(2)} KB`
        : '';
    const renderPreview = () => {
        if (!fileData)
            return null;
        switch (fileData.type) {
            case MEDIA_TYPE.IMAGE:
                return (React.createElement("img", { src: URL.createObjectURL(fileData.file), alt: fileData.file.name, className: s.innerContent }));
            case MEDIA_TYPE.VIDEO:
                return (React.createElement("video", { className: s.innerContent, controls: true },
                    React.createElement("source", { src: URL.createObjectURL(fileData.file) })));
            case MEDIA_TYPE.AUDIO:
                return (React.createElement("audio", { src: URL.createObjectURL(fileData.file), className: classnames(s.innerContent, s.audioPlayer), controls: true }));
            case MEDIA_TYPE.DOC:
                if (ext === 'pdf') {
                    return (React.createElement("div", { className: s.innerContent },
                        React.createElement(Document, { file: fileData.file, className: s.pdfDocument, onLoadSuccess: onPDFLoadSuccess, loading: React.createElement("div", { className: s.loadingIcon },
                                React.createElement(LoadingIcon, null)) },
                            React.createElement(Page, { pageNumber: 1, height: pageHeight, className: s.pdfPage, onLoadSuccess: onPageLoadSuccess }))));
                }
                else {
                    return (React.createElement("div", { className: s.noPreviewContainer },
                        React.createElement(Icon, { className: s.innerIcon, component: FileFilled }),
                        React.createElement("div", { className: s.innerTitle }, t('previewNotAvailable')),
                        React.createElement("div", { className: s.innerSubtitle },
                            size,
                            " - ",
                            ext)));
                }
            default:
                return (React.createElement("div", { className: s.noPreviewContainer },
                    React.createElement(Icon, { className: s.innerIcon, component: FileFilled }),
                    React.createElement("div", { className: s.innerTitle }, t('previewNotAvailable')),
                    React.createElement("div", { className: s.innerSubtitle },
                        size,
                        " - ",
                        ext)));
        }
    };
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.box },
            React.createElement("div", { className: s.header },
                React.createElement("div", { className: s.closePreview, onClick: () => toggleFilePreview(false) },
                    React.createElement(Icon, { component: QuickRepliesCross })),
                React.createElement("div", { className: s.nameWrapper },
                    React.createElement("div", { className: s.name }, fileData === null || fileData === void 0 ? void 0 : fileData.file.name),
                    hasPreview && React.createElement("div", { className: s.nameDetails }, size)),
                React.createElement("div", { className: s.placeholder })),
            React.createElement("div", { ref: innerRef, className: s.inner }, renderPreview()),
            !!activeChat && (React.createElement(Compose, Object.assign({}, composeProps, { onFileDrop: () => { }, sendTextMessageCB: sendFile, activeChat: activeChat, disabled: !!disabledMessage, disabledMessage: disabledMessage, onQuickReplySelected: () => { }, initialText: fileData === null || fileData === void 0 ? void 0 : fileData.caption, currentChatId: null, theme: theme, textMaxLength: captionMaxLength, buttonRef: composeButtonRef, maxRows: 3, previewMode: true }))))));
};
export default withStore(observer(FilePreview));
