import React from "react";
import blankProfilePicture from "../../../../assets/avatars/blankProfilePicture.png";
import './UserItemStyles.css'
import {ReactComponent as SingleCheck} from "../../../../assets/icons/chatStatuses/singleCheck.svg";
import Icon from '@ant-design/icons';
import UserItemLabel from "./UserItemLabel";
import {observer} from "mobx-react";
import { withStore } from '../../../../store/rootStore';

const UserItem = withStore(observer((props) => {
  const { user, isActive, setUser } = props, { departmentsStore } = props.store;

  const userDepartments = departmentsStore.departments.filter(department => department.agents?.indexOf(user.uid) > -1)

  return (
    <div
      className={isActive ? 'conversation-list-item user-item__active' : 'conversation-list-item'}
      onClick = {() => setUser(user.uid)}
    >
      <img className="conversation-photo" src={blankProfilePicture} />
      <div className={'conversation-info'}>
        <div className="conversation-title-wrapper">
          <h1 className="conversation-title">{ user.displayName || user.email }</h1>
          <div className="conversation-message user-item__department">
            { userDepartments.map((department,i) => <UserItemLabel key={i} department={department} />) }
          </div>
        </div>
        {isActive &&
          <div className="conversation-status-block user-item__img-container">
            <Icon component={SingleCheck} className={"user-item__img"} />
          </div>
        }
      </div>
    </div>
  )
}))

export default UserItem;
