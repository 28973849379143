var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Icon, { LeftOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Drawer, Dropdown, message, Tooltip, Typography } from "antd";
import classnames from "classnames";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { RiMailCheckLine, RiMailCloseLine } from "react-icons/ri";
import ChatStatusButtons from "../../MainPage/RightBar/atoms/ChatStatusButtons";
import BlockedChatModal from "../BlockedChat/BlockedChatModal";
import blankProfilePicture from "../../../assets/avatars/blankProfilePicture.png";
import exportLogo from "../../../assets/crmLogos/cloud-link-icon.svg";
import { ReactComponent as Star } from "../../../assets/icons/chatStatuses/star.svg";
import { ReactComponent as YellowStar } from "../../../assets/icons/chatStatuses/yellowStar.svg";
import { ReactComponent as LockClosed } from "../../../assets/icons/lock/lockClosed.svg";
import { ReactComponent as LockOpen } from "../../../assets/icons/lock/lockOpen.svg";
import { MOBILE_WIDTH } from "../../../constants";
import { getBackgroundColor, getDirection } from "../../../helpers/functions";
import { getFilePreviewUrl } from "../../../store/FilesStore";
import { withStore } from "../../../store/rootStore";
import CRM from "../../CRM";
import ChatIdInfo from "../ChatIdInfo";
import ChatTitle from "../ChatTitle";
import SubscribeModal from "./Components/SubscribeModal";
import styles from "./UserInfo.module.scss";
const { Text } = Typography;
class UserInfo extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                markImportantLoading: false,
                blockChatLoading: false,
                moreMenuVisible: false,
                showChatMenu: false,
                showBlockModal: false,
                showSubscribeModal: false,
                showMobileCRMDrawer: false,
                imageUrl: '',
            }
        });
        Object.defineProperty(this, "closeMoreMenu", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ moreMenuVisible: false });
            }
        });
        Object.defineProperty(this, "toggleMobileCRMDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (flag) => {
                this.setState({ showMobileCRMDrawer: flag });
            }
        });
        Object.defineProperty(this, "menu", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { activeChat } = this.props, unsubscribed = !!activeChat.unsubscribed || !!activeChat.blockTemplates;
                const items = [
                    {
                        key: "markAsImportant",
                        icon: React.createElement(Icon, { className: styles.menuIcon, component: activeChat.important ? YellowStar : Star }),
                        label: React.createElement(Trans, { i18nKey: activeChat.important ? "unmarkAsImportant" : "markAsImportant" }),
                        onClick: this.markAsImportant,
                    },
                    {
                        key: "unsubscribeFromTM",
                        icon: (React.createElement(Icon, { className: styles.menuIcon, component: unsubscribed ? RiMailCheckLine : RiMailCloseLine })),
                        label: React.createElement(Trans, { i18nKey: unsubscribed ? "resubscribeToTM" : "unsubscribeFromTM" }),
                        onClick: () => this.setState({ showSubscribeModal: true }),
                    },
                    {
                        key: "blockUser",
                        icon: (React.createElement(Icon, { className: classnames(styles.menuIcon, styles.errorIcon), component: ((_a = activeChat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) ? LockOpen : LockClosed })),
                        label: (React.createElement(Text, { className: styles.alertText },
                            React.createElement(Trans, { i18nKey: ((_b = activeChat.blockedChat) === null || _b === void 0 ? void 0 : _b.blocked) ? "unblockUser" : "blockUser" }))),
                        onClick: () => {
                            var _a;
                            if ((_a = activeChat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) {
                                this.unblockUser();
                            }
                            else {
                                this.setState({ showBlockModal: true });
                            }
                        },
                    },
                ];
                return { items };
            }
        });
        Object.defineProperty(this, "markAsImportant", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { activeChat } = this.props;
                this.setState({ markImportantLoading: true });
                yield this.props.store.chatsStore
                    .patchChat(activeChat._id, { important: !activeChat.important })
                    .then(() => {
                    this.setState({ markImportantLoading: false });
                })
                    .catch((error) => {
                    console.error(error);
                });
            })
        });
        Object.defineProperty(this, "unsubscribeFromTM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { activeChat } = this.props, unsubscribed = !!activeChat.unsubscribed || !!activeChat.blockTemplates;
                yield this.props.store.chatsStore
                    .subscribeUser(activeChat._id, unsubscribed)
                    .then(() => {
                    message.success(React.createElement(Trans, { i18nKey: unsubscribed ? "successResubscribeMessage" : "successUnsubscribeMessage" }));
                })
                    .catch((error) => {
                    console.error(error);
                });
            })
        });
        Object.defineProperty(this, "setProfilePicture", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const imageUrl = ((_a = this.props.activeChat) === null || _a === void 0 ? void 0 : _a.image)
                    ? getFilePreviewUrl(this.props.activeChat.image)
                    : blankProfilePicture;
                this.setState({ imageUrl });
            }
        });
        Object.defineProperty(this, "blockUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                this.setState({ blockChatLoading: true });
                yield this.props.store.activeChatStore.blockUnblockChat().then(() => this.setState({ blockChatLoading: false }));
            })
        });
        Object.defineProperty(this, "unblockUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                yield this.props.store.activeChatStore.unblockChat();
            })
        });
        Object.defineProperty(this, "setChatMenuOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.setState({ showChatMenu: !this.state.showChatMenu })
        });
    }
    componentDidMount() {
        this.setProfilePicture();
    }
    componentDidUpdate(prevProps) {
        var _a, _b, _c;
        if ((((_a = this.props.activeChat) === null || _a === void 0 ? void 0 : _a.image) && !this.state.imageUrl) ||
            (((_b = this.props.activeChat) === null || _b === void 0 ? void 0 : _b._id) !== ((_c = prevProps.activeChat) === null || _c === void 0 ? void 0 : _c._id))) {
            this.setProfilePicture();
        }
    }
    render() {
        var _a, _b, _c;
        const { activeChat, store } = this.props;
        const { activeChatStore, crmStore } = store;
        const { showBlockModal, showSubscribeModal, showMobileCRMDrawer, imageUrl, moreMenuVisible } = this.state;
        const { theme } = activeChatStore;
        const background = getBackgroundColor(theme);
        const unsubscribed = !!activeChat.unsubscribed || !!activeChat.blockTemplates;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.userInfo, style: { background } },
                React.createElement("div", { className: styles.userInfoData },
                    React.createElement(LeftOutlined, { className: classnames(styles.userInfoBack, {
                            [styles.inverted]: theme.invertColors,
                            [styles.rtl]: getDirection() === 'rtl',
                        }), onClick: () => activeChatStore.removeActiveChat() }),
                    React.createElement("div", { className: styles.photoWrapper },
                        React.createElement("img", { src: imageUrl || blankProfilePicture, className: styles.userInfoAvatar }),
                        React.createElement("img", { className: styles.userInfoAvatarSmall, src: theme.icon })),
                    React.createElement("div", { className: classnames(styles.userInfoText, {
                            [styles.rtl]: getDirection() === 'rtl',
                        }) },
                        React.createElement("div", { className: classnames(styles.userInfoName, {
                                [styles.inverted]: theme.invertColors,
                            }) },
                            unsubscribed && (React.createElement(Tooltip, { overlayStyle: { maxWidth: 300 }, placement: "right", title: React.createElement("div", { className: styles.unsubscribedTooltipContainer },
                                    React.createElement("div", { className: styles.unsubscribedTooltipInfo },
                                        React.createElement(Trans, { i18nKey: "unsubscribedFromTemplateMessages" })),
                                    React.createElement("div", { className: styles.unsubscribedTooltipAction, onClick: () => this.setState({ showSubscribeModal: true }) },
                                        React.createElement(Trans, { i18nKey: "resubscribe" }))) },
                                React.createElement(Icon, { className: classnames(styles.menuIcon, styles.unsubscribedIcon), component: RiMailCloseLine }))),
                            React.createElement(ChatTitle, { chat: activeChat, showId: "none", className: classnames({ inverted: theme.invertColors }) })),
                        React.createElement(ChatIdInfo, { chat: activeChat, inverted: theme.invertColors }))),
                React.createElement("div", null, window.innerWidth > MOBILE_WIDTH && (React.createElement(Dropdown, { trigger: ["click"], placement: "bottomRight", menu: this.menu() },
                    React.createElement(MoreOutlined, { className: classnames(styles.openMenuIcon, {
                            [styles.inverted]: (_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertDotsColor,
                        }), onClick: this.setChatMenuOpen })))),
                window.innerWidth <= MOBILE_WIDTH && (React.createElement("div", { className: classnames(styles.mobileActionBar, {
                        [styles.inverted]: theme.invertColors,
                        [styles.darken]: (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.darkenHeaderColor,
                    }) },
                    React.createElement(MoreOutlined, { className: classnames(styles.moreButtonMobile, "moreButtonMobile"), onClick: () => this.setState({ moreMenuVisible: !moreMenuVisible }) }),
                    React.createElement(Button, { onClick: () => this.toggleMobileCRMDrawer(true), className: styles.crmIcon },
                        React.createElement("img", { src: exportLogo, alt: "crm-logo" })))),
                React.createElement(ChatStatusButtons, { visible: moreMenuVisible, onComplete: this.closeMoreMenu }),
                React.createElement(BlockedChatModal, { visible: showBlockModal, onCancel: () => this.setState({ showBlockModal: false }), onSubmit: this.blockUser, name: activeChat.title, phone: activeChat.clientPhoneE164, block: !((_c = activeChat.blockedChat) === null || _c === void 0 ? void 0 : _c.blocked) }),
                React.createElement(Drawer, { width: "calc(100% - 48px)", placement: "right", closable: false, className: styles.crmDrawer, open: showMobileCRMDrawer, onClose: () => this.toggleMobileCRMDrawer(false) },
                    React.createElement(CRM, null))),
            React.createElement(SubscribeModal, { onCancel: () => this.setState({ showSubscribeModal: false }), onOk: () => {
                    this.unsubscribeFromTM();
                    this.setState({ showSubscribeModal: false });
                }, visible: showSubscribeModal, activeChat: activeChat })));
    }
}
export default withTranslation()(withStore(observer(UserInfo)));
