import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Trans, withTranslation } from 'react-i18next';
import { withStore } from '../../store/rootStore';
import { USER_STATES } from '../../constants';
import Logo from '../../assets/logos/texterLogos/texter-logo-square2-192x192.png';
import LanguageSelect from '../LeftDrawer/LanguageSelect';
import { MyInput } from '../Base/Form';
import styles from './Login.module.scss';
import { Redirect } from 'react-router-dom';
class Login extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "checkIfUserIsLoggedInIntervalId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setErrorMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (errorCode) => {
                switch (errorCode) {
                    case 'auth/user-not-found':
                        this.setState({ errorCode: 'emailError' });
                        break;
                    case 'auth/wrong-password':
                        this.setState({ errorCode: 'passwordError' });
                        break;
                    case 'auth/user-disabled':
                        this.setState({ errorCode: 'disabledError' });
                        break;
                    case 'auth/wrong-password':
                        this.setState({ errorCode: 'auth/wrong-password' });
                        break;
                    case 'auth/too-many-requests':
                        this.setState({ errorCode: 'auth/too-many-requests' });
                        break;
                    case 'auth/invalid-email':
                        this.setState({ errorCode: 'auth/invalid-email' });
                        break;
                    default:
                        this.setState({ errorCode: 'loginConnectionError' });
                }
                setTimeout(() => this.setState({ errorCode: '' }), 5000);
            }
        });
        Object.defineProperty(this, "handleChangeEmail", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({ email: e.target.value, showValidationMessage: false });
            }
        });
        Object.defineProperty(this, "handleChangePassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.setState({ password: value, showValidationMessage: false });
            }
        });
        Object.defineProperty(this, "handleChangeNewPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.setState({ newPassword: value, showValidationMessage: false });
            }
        });
        Object.defineProperty(this, "handleSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.preventDefault();
                this.setState({ showValidationMessage: false });
                const { email, password, newPassword } = this.state;
                const { store } = this.props;
                const trimmedEmail = email.trim();
                store.loginStore.logIn(trimmedEmail, password, newPassword).then(() => {
                    if (store.loginStore.userState === USER_STATES.LOGGED_IN) {
                        this.props.history.push('/');
                    }
                });
            }
        });
        Object.defineProperty(this, "handleResetPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.state.email) {
                    this.setState({ errorCode: '' });
                    this.props.store
                        .resetPassword(this.state.email)
                        .then(() => {
                        this.setState({ checkEmail: true });
                    })
                        .catch((e) => {
                        if (e.error.message === 'EMAIL_NOT_FOUND') {
                            this.setState({ errorCode: 'emailNotFound' });
                        }
                        else if (e.error.message === 'RESET_PASSWORD_EXCEED_LIMIT') {
                            this.setState({ errorCode: 'exceededLimit' });
                        }
                        else if (e.error.message === 'INVALID_EMAIL') {
                            this.setState({ errorCode: 'invalidEmail' });
                        }
                        else {
                            this.setState({ errorCode: 'passResetError' });
                        }
                    });
                }
                else {
                    this.setState({ errorCode: 'enterYourEmail' });
                }
                setTimeout(() => this.setState({ checkEmail: null, errorCode: '' }), 3000);
            }
        });
        Object.defineProperty(this, "showValidationMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ showValidationMessage: true });
            }
        });
        Object.defineProperty(this, "changeProject", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        event: 'goToProjectSelect',
                    }));
                }
            }
        });
        this.state = {
            email: '',
            password: '',
            newPassword: '',
            errorCode: '',
            checkEmail: null,
            showValidationMessage: false,
            disabled: false,
            partnerTheme: props.store.getPartnerTheme(),
        };
    }
    render() {
        const { store } = this.props;
        const { partnerTheme } = this.state;
        const { loading, errorMessage, showNewPassword, showPasswordReset } = store.loginStore;
        const logInBtnEnabled = !loading && this.state.email.length > 0 && this.state.password.length > 0;
        if (store.loginStore.userState === USER_STATES.LOGGED_IN) {
            return React.createElement(Redirect, { to: "/" });
        }
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.topBar },
                    React.createElement("div", { className: styles.topBarContent },
                        React.createElement("img", { className: classnames(styles.logo, {
                                [styles.custom]: !!partnerTheme,
                            }), src: partnerTheme ? partnerTheme.icon : Logo }),
                        React.createElement("div", { className: styles.centralTextBlock },
                            React.createElement("div", { className: styles.mainText }, partnerTheme ? (React.createElement(Trans, { i18nKey: "loginTo", values: { name: partnerTheme.name } })) : (React.createElement(Trans, { i18nKey: "loginToTexterchat" })))),
                        React.createElement(LanguageSelect, { className: styles.languageSelect }))),
                React.createElement("form", { className: styles.passwordInputBlock, onSubmit: this.handleSubmit },
                    React.createElement("label", { className: styles.authLabel, htmlFor: "email" },
                        React.createElement(Trans, { i18nKey: "email" })),
                    React.createElement(Input, { className: styles.emailInput, id: "email", onChange: this.handleChangeEmail }),
                    React.createElement("label", { className: styles.authLabel, htmlFor: "password" },
                        React.createElement(Trans, { i18nKey: "password" })),
                    React.createElement(MyInput.Password, { value: this.state.password, onChange: this.handleChangePassword, className: styles.passwordInput, size: "middle", type: "text", id: "password", placeholder: "- - - -", iconRender: (visible) => (visible ? React.createElement(EyeTwoTone, null) : React.createElement(EyeInvisibleOutlined, null)) }),
                    showNewPassword && (React.createElement(React.Fragment, null,
                        React.createElement("label", { className: styles.authLabel, htmlFor: "newPassword" },
                            React.createElement(Trans, { i18nKey: "newPassword" })),
                        React.createElement(MyInput.Password, { value: this.state.newPassword, onChange: this.handleChangeNewPassword, className: styles.passwordInput, size: "middle", type: "text", id: "newPassword", placeholder: "- - - -", iconRender: (visible) => (visible ? React.createElement(EyeTwoTone, null) : React.createElement(EyeInvisibleOutlined, null)) }))),
                    React.createElement("div", { className: styles.submitWrapper, onMouseEnter: this.showValidationMessage },
                        React.createElement("button", { disabled: !logInBtnEnabled || loading, className: classnames(styles.submitSendPassword, {
                                [styles.submitSendPasswordDisabled]: !logInBtnEnabled,
                            }) }, !loading ? React.createElement(Trans, { i18nKey: "login" }) : React.createElement(LoadingOutlined, null)))),
                React.createElement("p", { className: styles.termsAndPrivacy },
                    React.createElement(Trans, { i18nKey: "termsAndPrivacy", components: {
                            1: React.createElement("a", { href: "https://texterchat.com/terms", target: "_blank" }),
                            2: React.createElement("a", { href: "https://texterchat.com/privacy", target: "_blank" }),
                        } })),
                React.createElement("div", { className: styles.messagesBlock },
                    errorMessage ? (React.createElement("p", { className: classnames(styles.notificationText, styles.loginError) }, errorMessage)) : null,
                    this.state.checkEmail ? (React.createElement("p", { className: classnames(styles.notificationText, styles.loginEmailCheck) },
                        React.createElement(Trans, { i18nKey: "checkEmail" }))) : null),
                showPasswordReset && (React.createElement("a", { className: styles.linkButton, onClick: () => this.handleResetPassword() },
                    React.createElement(Trans, { i18nKey: "resetPassword" }))),
                window.ReactNativeWebView && (React.createElement("a", { className: styles.linkButton, onClick: () => this.changeProject() },
                    React.createElement(Trans, { i18nKey: "changeProject" }))))));
    }
}
export default withTranslation()(withStore(observer(Login)));
