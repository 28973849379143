import React from "react";

import './UserItemLabelStyles.css'
import { Typography } from 'antd';
const { Text } = Typography;

const UserItemLabel = props => {
  const { department } = props;
  return (
    <div className={'user-item-label'}>
      <div style={{background: department.color}} className={'user-item-label__square'} />
      <Text> { department.name } </Text>
    </div>
  )
}
export default UserItemLabel;
