import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Progress, Tooltip } from 'antd';
import { formatNumberToShortString } from '../../../helpers/string';
import LoadingIcon from '../../Chat/common/LoadingIcon';
import s from './TemplateMessageDrawer.module.scss';
const LimitDashboard = ({ limit, sent, loading }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s['template-message__limitContainer'] },
        React.createElement("div", { className: s['template-message__limitRow'] },
            React.createElement("div", { className: s['template-message__text'] },
                React.createElement("strong", null, loading && !sent ? (React.createElement(LoadingIcon, { additionalStyles: s['template-message__loadingIcon'] })) : (sent)),
                ' ',
                "of ",
                React.createElement("strong", null,
                    formatNumberToShortString(limit),
                    "/24hr"),
                " Messages Used"),
            React.createElement(Tooltip, { title: t('sendLimitTemplateMenuMessage'), overlayClassName: s['limit-dashboard__tooltip'] },
                React.createElement(InfoCircleOutlined, { className: s['template-message__infoIcon'] }))),
        React.createElement("div", { className: s['template-message__limitRow'] },
            React.createElement(Progress, { percent: Math.round((sent / limit) * 100), strokeColor: "#0FB878" // $primary_green
                , className: s['template-message__progressBar'] }))));
};
export default LimitDashboard;
