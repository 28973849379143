import { MessagingChannelFeature } from '@whatsper/texterchat-common';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../store/rootStore';
import { formatPhone } from '../../Base/Phone/Formatter';
import s from './ChatIdInfo.module.scss';
const NO_ID_FALLBACK = '---';
function ChatIdInfo({ chat, className, inverted, }) {
    const { phoneFormat, defaultCountry, channels } = useStore(), [idFormatted, setIdFormatted] = useState(NO_ID_FALLBACK), [accountName, setAccountName] = useState('');
    useEffect(() => {
        const adapter = channels.adaptersByAccountId[`${chat.channelInfo.name}${chat.channelInfo.accountId}`];
        if (adapter) {
            if (channels.hasFeatures([MessagingChannelFeature.contactPhoneNumberId], adapter)) {
                setIdFormatted(formatPhone(chat.channelInfo.id, phoneFormat, defaultCountry));
            }
            else if (channels.hasFeatures([MessagingChannelFeature.contactGlobalId], adapter)) {
                setIdFormatted(chat.channelInfo.id);
            }
            else {
                setIdFormatted(NO_ID_FALLBACK);
            }
        }
        else {
            setIdFormatted(chat.channelInfo.id);
        }
    }, [chat._id, channels.adaptersByAccountId, phoneFormat, defaultCountry]);
    useEffect(() => {
        var _a;
        const account = channels.accountsById[`${chat.channelInfo.name}${chat.channelInfo.accountId}`];
        if (account) {
            setAccountName(account.title || ((_a = account.metadata) === null || _a === void 0 ? void 0 : _a.accountTitle) || account.accountId);
        }
    }, [chat._id, channels.accountsById]);
    return (React.createElement("div", { className: classnames(s.container, className, {
            [s.inverted]: inverted,
        }) },
        React.createElement("div", { className: s.userInfoPhone }, idFormatted),
        React.createElement("div", null, "|"),
        accountName && React.createElement("div", { className: s.accountName }, accountName)));
}
/**
 * Component to display chat formatted ID and channel account title
 */
export default observer(ChatIdInfo);
