import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import { Divider, Typography } from 'antd';
import classnames from 'classnames';
import Icon, { FileTextFilled } from '@ant-design/icons';
import { RiMailCloseLine } from 'react-icons/ri';
import { MessageContentsType, MEDIA_TYPE, isMediaMessage } from '@whatsper/texterchat-common';
import { BsFillCameraFill, BsFillCameraVideoFill, BsStickyFill } from 'react-icons/bs';
import { FaMicrophone, FaUser } from 'react-icons/fa';
import { IoLocation } from 'react-icons/io5';
import HighlightedText from '../../Base/Utils/HighlightedText';
import ChatStatusTitle from '../common/ChatStatusTitle';
import ChatDepartmentTitle from '../common/ChatDepartmentTitle';
import blankProfilePicture from '../../../assets/avatars/blankProfilePicture.png';
import { TIME_FORMAT } from '../../../constants';
import { ReactComponent as YellowStar } from '../../../assets/icons/chatStatuses/yellowStar.svg';
import { ReactComponent as LockClosed } from '../../../assets/icons/lock/lockClosed.svg';
import { withStore } from '../../../store/rootStore';
import { getFilePreviewUrl } from '../../../store/FilesStore';
import styles from './ChatsListItem.module.scss';
import ChatTitle from '../ChatTitle';
const { Text } = Typography;
class ChatsListItem extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "showUnreadMessagesCounter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (unreadCount) => {
                if (unreadCount > 0) {
                    return (React.createElement("div", { className: styles.unreadMessages },
                        React.createElement("p", { className: styles.unreadMessagesNumber }, unreadCount)));
                }
            }
        });
        /**
         * @deprecated This method is fallback for old chats that don't have lastMessage field
         */
        Object.defineProperty(this, "getLastMessage__depreciated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { lastText } = this.props.chat;
                return lastText ? this.shortenPreviewText(lastText) : '---';
            }
        });
        Object.defineProperty(this, "shortenPreviewText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                let shortMessage = '..';
                try {
                    text = text.replace(/\\n/g, '\\\\n').replace(/\r/g, '\\\\r').replace(/\\/g, '  ');
                    let appendDots = '';
                    if (text.length > 22) {
                        appendDots = '..';
                    }
                    if (text.indexOf('{"text"') == 0) {
                        shortMessage = text.substring(9, 31).replace('"}', '');
                    }
                    else {
                        shortMessage = text.substring(0, 22);
                    }
                    shortMessage = shortMessage.trim() + appendDots;
                }
                catch (e) {
                    console.log('Error trying to shorten preview text', text);
                }
                return shortMessage;
            }
        });
        Object.defineProperty(this, "getLastMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { search, chat } = this.props;
                const { lastMessage } = chat;
                if (!lastMessage) {
                    return this.getLastMessage__depreciated();
                }
                const icons = {
                    [MEDIA_TYPE.IMAGE]: React.createElement(BsFillCameraFill, null),
                    [MEDIA_TYPE.VIDEO]: React.createElement(BsFillCameraVideoFill, null),
                    [MEDIA_TYPE.AUDIO]: React.createElement(FaMicrophone, null),
                    [MEDIA_TYPE.DOC]: React.createElement(FileTextFilled, null),
                    [MEDIA_TYPE.STICKER]: React.createElement(BsStickyFill, null),
                };
                const text = {
                    [MEDIA_TYPE.IMAGE]: 'Photo',
                    [MEDIA_TYPE.VIDEO]: 'Video',
                    [MEDIA_TYPE.AUDIO]: 'Audio',
                    [MEDIA_TYPE.DOC]: isMediaMessage(lastMessage) && lastMessage.media[0].filename,
                    [MEDIA_TYPE.STICKER]: 'Sticker',
                };
                switch (lastMessage.type) {
                    case MessageContentsType.text:
                        return (search).length > 0 ? (React.createElement(HighlightedText, { text: lastMessage.text, highlighted: search })) : (lastMessage.text);
                    case MessageContentsType.postback:
                        const value = lastMessage.postback.title || lastMessage.postback.payload;
                        return (search === null || search === void 0 ? void 0 : search.length) > 0 ? (React.createElement(HighlightedText, { text: value, highlighted: search })) : (value);
                    case MessageContentsType.media:
                        return (React.createElement("span", { className: styles.mediaLastMessage },
                            icons[lastMessage.media[0].mediaType],
                            React.createElement("span", null, text[lastMessage.media[0].mediaType])));
                    case MessageContentsType.location:
                        return (React.createElement("span", { className: styles.mediaLastMessage },
                            React.createElement(IoLocation, null),
                            React.createElement("span", null, "Location")));
                    case MessageContentsType.contacts:
                        let contact = '';
                        if ((_a = lastMessage.contacts[0].phones) === null || _a === void 0 ? void 0 : _a.length) {
                            contact = lastMessage.contacts[0].phones[0].phone;
                        }
                        else if ((_b = lastMessage.contacts[0].emails) === null || _b === void 0 ? void 0 : _b.length) {
                            contact = lastMessage.contacts[0].emails[0].email;
                        }
                        else {
                            contact = lastMessage.contacts[0].name.formatted_name;
                        }
                        return (React.createElement("span", { className: styles.mediaLastMessage },
                            React.createElement(FaUser, null),
                            React.createElement("span", null, contact)));
                    case MessageContentsType.special:
                        if (lastMessage.text) {
                            return this.shortenPreviewText(lastMessage.text);
                        }
                    default:
                        return this.getLastMessage__depreciated();
                }
            }
        });
        Object.defineProperty(this, "getChatAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chat) => {
                return this.props.store.agentsStore.users.find((agent) => {
                    if (chat.agent && chat.agent.uid) {
                        return chat.agent.uid === agent.uid;
                    }
                    else if (chat.agent) {
                        return chat.agent.uid === agent.uid;
                    }
                    else
                        return false;
                });
            }
        });
        Object.defineProperty(this, "getLastMessageDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.props.chat.last_message_timestamp)
                    return null;
                const lastMessageTimestamp = new Date(this.props.chat.last_message_timestamp);
                const today = new Date();
                const timeDifference = Math.abs(today.getTime() - lastMessageTimestamp.getTime());
                const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                if (today.getDate() === lastMessageTimestamp.getDate() &&
                    today.getMonth() === lastMessageTimestamp.getMonth() &&
                    today.getFullYear() === lastMessageTimestamp.getFullYear()) {
                    let hours = lastMessageTimestamp.getHours();
                    let minutes = lastMessageTimestamp.getMinutes();
                    if (minutes < 10) {
                        minutes = `0${minutes}`;
                    }
                    let formattedTime = hours + ':' + minutes;
                    if (this.props.store.timeFormat === TIME_FORMAT.TWELVE_HOURS) {
                        if (hours > 12) {
                            let leadingZero = '0';
                            if (hours - 12 >= 10) {
                                leadingZero = '';
                            }
                            formattedTime = leadingZero + (hours - 12) + ':' + minutes + 'PM';
                        }
                        else if (hours === 12 && lastMessageTimestamp.getMinutes() > 0) {
                            formattedTime += 'PM';
                        }
                        else if (hours < 12) {
                            let leadingZero = '0';
                            if (hours >= 10) {
                                leadingZero = '';
                            }
                            formattedTime = leadingZero + hours + ':' + minutes + 'AM';
                        }
                        else
                            formattedTime += 'AM';
                    }
                    return `${formattedTime}`;
                }
                else if (today.getDate() !== lastMessageTimestamp.getDate() && dayDifference < 7) {
                    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    if (dayDifference < 2) {
                        return React.createElement(Trans, { i18nKey: "yesterdayTime" });
                    }
                    return React.createElement(Trans, { i18nKey: weekDays[lastMessageTimestamp.getDay()] });
                }
                else {
                    const day = lastMessageTimestamp.getDate();
                    let month = lastMessageTimestamp.getMonth() + 1;
                    if (month < 10) {
                        month = `0${month}`;
                    }
                    const year = lastMessageTimestamp.getFullYear().toString().substr(-2);
                    return `${day}/${month}/${year}`;
                }
            }
        });
        Object.defineProperty(this, "onAvatarError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ avatar: blankProfilePicture });
            }
        });
        this.state = {
            avatar: blankProfilePicture,
        };
    }
    componentDidMount() {
        const { image } = this.props.chat;
        if (image) {
            const avatar = getFilePreviewUrl(image.toString());
            this.setState({ avatar });
        }
    }
    render() {
        var _a, _b, _c, _d;
        const { handleActive, chat, search, className, divider, showUnreadCount, showActive, store } = this.props;
        const activeChat = store.activeChatStore.chat;
        const { channels } = store;
        const icon = (_c = channels.themes[((_a = chat.channelInfo) === null || _a === void 0 ? void 0 : _a.accountId) || ((_b = chat.channelInfo) === null || _b === void 0 ? void 0 : _b.name)]) === null || _c === void 0 ? void 0 : _c.icon;
        const agent = this.getChatAgent(chat);
        const unsubscribed = !!chat.unsubscribed || !!chat.blockTemplates;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classnames(styles.listItem, { [styles.listItemActive]: showActive && activeChat && chat._id === activeChat._id }, className), onClick: handleActive ? () => handleActive(chat._id) : undefined },
                React.createElement("div", { className: styles.photoWrapper },
                    React.createElement("img", { className: styles.photo, src: this.state.avatar, onError: this.onAvatarError }),
                    React.createElement("img", { className: styles.photoSmallLogo, src: icon })),
                React.createElement("div", { className: classnames(styles.info, { [styles.unread]: chat.unreadCount <= 0 }) },
                    React.createElement("div", { className: styles.titleWrapper },
                        React.createElement("div", { className: styles.titleInnerWrapper },
                            React.createElement("div", { className: styles.titleImportanceWrapper },
                                chat.important && React.createElement(Icon, { component: YellowStar, className: styles.statusIcon }),
                                ((_d = chat.blockedChat) === null || _d === void 0 ? void 0 : _d.blocked) && (React.createElement(Icon, { component: LockClosed, className: classnames(styles.statusIcon, styles.errorIcon) })),
                                unsubscribed && (React.createElement(Icon, { className: classnames(styles.unsubscribedIcon, styles.statusIcon), component: RiMailCloseLine })),
                                React.createElement("div", { className: styles.title },
                                    React.createElement(ChatTitle, { chat: chat, highlighted: search }))),
                            React.createElement(ChatStatusTitle, { agentName: agent ? agent.displayName : null, agentColor: agent ? agent.color : null, status: chat.status, listItem: true })),
                        React.createElement("div", { className: styles.messageBlock },
                            React.createElement("p", { className: styles.message, dir: "auto" }, this.getLastMessage()),
                            React.createElement("div", { className: styles.lastMessageInfo },
                                React.createElement("div", { className: styles.lastMessageDate }, this.getLastMessageDate()),
                                showUnreadCount && this.showUnreadMessagesCounter(chat.unreadCount))),
                        React.createElement("div", { className: styles.assignedInfo }, !!chat.departmentId && React.createElement(ChatDepartmentTitle, { departmentId: chat.departmentId }))))),
            divider ? React.createElement(Divider, { className: styles.divider }) : null));
    }
}
Object.defineProperty(ChatsListItem, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        divider: true,
        showUnreadCount: true,
        showActive: true,
        search: '',
    }
});
export default withStore(observer(ChatsListItem));
