var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import { CHAT_STATUS, isRegularMessage, isTextMessage, MessagingChannelFeature, Permissions } from "@whatsper/texterchat-common";
import { isArray } from "lodash";
import { message } from "antd";
import AcceptChatBlock from "../AcceptChatBlock";
import SessionIsOverBlock from "../SessionIsOverBlock";
import ChatsList from "../ChatsList";
import MessagesList from "../MessagesList";
import Compose from "../Compose";
import UserInfo from "../UserInfo/UserInfo";
import NoActiveChatSelected from "../NoActiveChatSelected";
import QuickReplies from "../QuickReplies/ActivityBar";
import LoadingScreen from "../../LoadingScreen";
import defaultBackground from "../../../assets/themePresetsChannel/backgrounds/defaultBackground.jpeg";
import ReplyPreview from "../ReplyPreview";
import ScrollToUnreadButton from "../ScrollToUnreadButton";
import { INPUT_BLOCK, MOBILE_WIDTH } from "../../../constants";
import BlockedChatBar from "../BlockedChat/BlockedChatBar";
import DragAndDrop from "../DragAndDrop";
import SendLocationWindow from "../SendLocationWindow";
import styles from "./ChatWindow.module.scss";
import { withStore } from "../../../store/rootStore";
import { MEDIA_TYPE } from "@whatsper/texterchat-common/constants";
import FilePreview from "../FilePreview";
import ErrorBoundary from "../../ErrorBoundary";
import DefaultTemplateReplyBar from "../DefaultTemplateBar";
import NoChatViewPermission from "../NoChatViewPermission";
import { Trans } from "react-i18next";
/**
 * @todo THIS COMPONENT STILL VERY NEED DEEP REFACTORING !!!
 */
class Messenger extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "messagesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "supportsReply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { activeChatStore, channels } = this.props.store;
                const adapter = activeChatStore.chat ? channels.getAccountAdapter({
                    id: (_a = activeChatStore.chat) === null || _a === void 0 ? void 0 : _a.channelInfo.accountId,
                    name: (_b = activeChatStore.chat) === null || _b === void 0 ? void 0 : _b.channelInfo.name,
                }) : null;
                return channels.hasFeatures([MessagingChannelFeature.outboundMessageQuote], adapter);
            }
        });
        Object.defineProperty(this, "sendReplyMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                const { activeReplyMessage, sendTextMessage } = this.props.store.activeChatStore;
                if (this.supportsReply()) {
                    const messageQuotedId = activeReplyMessage === null || activeReplyMessage === void 0 ? void 0 : activeReplyMessage.channelInfo.id;
                    sendTextMessage(text, messageQuotedId);
                }
                else {
                    if (isTextMessage(activeReplyMessage) && activeReplyMessage.text) {
                        text = `> ${activeReplyMessage.text}\n\n${text}`;
                    }
                    else if (isRegularMessage(activeReplyMessage)) {
                        text = `> ${activeReplyMessage.type}\n\n${text}`;
                    }
                    ;
                    sendTextMessage(text);
                }
            }
        });
        Object.defineProperty(this, "sendTextMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                const { activeReplyMessage, sendTextMessage } = this.props.store.activeChatStore;
                if (!activeReplyMessage) {
                    sendTextMessage(text);
                }
                else {
                    this.sendReplyMessage(text);
                }
            }
        });
        Object.defineProperty(this, "beforeUpload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file) => {
                var _a, _b, _c;
                const { activeChatStore, channels } = this.props.store;
                const { chat: activeChat } = activeChatStore;
                if (!activeChat)
                    return false;
                const adapter = channels.getAccountAdapter({
                    id: (_a = activeChat.channelInfo) === null || _a === void 0 ? void 0 : _a.accountId,
                    name: (_b = activeChat.channelInfo) === null || _b === void 0 ? void 0 : _b.name,
                });
                const mediaLimits = adapter === null || adapter === void 0 ? void 0 : adapter.messageLimits.media;
                const prettySize = (size) => {
                    return Math.round((size / 1024 / 1024) * 100) / 100;
                };
                if (file.type.startsWith('image') && (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.image)) {
                    if (file.size > mediaLimits.image.maxSize) {
                        message.error(React.createElement(Trans, { i18nKey: "tooLargeUploadMessage", values: { size: prettySize(mediaLimits.image.maxSize) } }));
                        return false;
                    }
                }
                if (file.type.startsWith('video') && (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.video)) {
                    if (file.size > mediaLimits.video.maxSize) {
                        message.error(React.createElement(Trans, { i18nKey: "tooLargeUploadMessage", values: { size: prettySize(mediaLimits.video.maxSize) } }));
                        return false;
                    }
                }
                if (file.type.startsWith('audio') && (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.audio)) {
                    if (file.size > mediaLimits.audio.maxSize) {
                        message.error(React.createElement(Trans, { i18nKey: "tooLargeUploadMessage", values: { size: prettySize(mediaLimits.audio.maxSize) } }));
                        return false;
                    }
                }
                if (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document) {
                    if (file.size > ((_c = mediaLimits.document) === null || _c === void 0 ? void 0 : _c.maxSize)) {
                        message.error(React.createElement(Trans, { i18nKey: "tooLargeUploadMessage", values: { size: prettySize(mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document.maxSize) } }));
                        return false;
                    }
                }
                return true;
            }
        });
        Object.defineProperty(this, "onFileDrop", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file, caption = this.state.composeText) => {
                if (file === null || file === void 0 ? void 0 : file.type) {
                    const success = this.beforeUpload(file);
                    if (!success)
                        return;
                    let type = file.type.split("/")[0];
                    if (![MEDIA_TYPE.IMAGE, MEDIA_TYPE.VIDEO, "audio"].includes(type)) {
                        type = MEDIA_TYPE.DOC;
                    }
                    this.props.store.activeChatStore.setFileData({ file, type, caption });
                    this.props.store.activeChatStore.toggleFilePreview(true);
                    this.onComposeTextChange("");
                }
            }
        });
        Object.defineProperty(this, "onQuickReplySelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (reply) => {
                this.setState({ quickReply: reply.text });
            }
        });
        Object.defineProperty(this, "handleBackgrounds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat, theme } = this.props.store.activeChatStore;
                const color = isArray(theme.color) ? theme.color[0] : theme.color;
                if (chat) {
                    return `linear-gradient(${color}30, ${color}30), url(${defaultBackground})`;
                }
                ;
                return `url(${defaultBackground})`;
            }
        });
        Object.defineProperty(this, "handlePaste", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e, allowPaste) => {
                var _a;
                if (!allowPaste)
                    return;
                if ((_a = e.clipboardData.files) === null || _a === void 0 ? void 0 : _a.length) {
                    e.preventDefault();
                    this.onFileDrop(e.clipboardData.files[0]);
                }
            }
        });
        Object.defineProperty(this, "onComposeTextChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState({ composeText: text });
            }
        });
        Object.defineProperty(this, "scrollToUnread", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.messagesList) {
                    this.messagesList.scrollToUnread();
                }
            }
        });
        Object.defineProperty(this, "scrollToBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.messagesList) {
                    this.messagesList.scrollToBottom();
                }
            }
        });
        Object.defineProperty(this, "inputBlockToShow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { activeChatStore, loginStore } = this.props.store, { chat: chat, sessionIsOver, filePreview, sendLocationCoords } = activeChatStore, { userDetails } = loginStore, acceptChatCondition = chat && (chat.status !== CHAT_STATUS.ASSIGNED || (chat.agent && (userDetails === null || userDetails === void 0 ? void 0 : userDetails.uid) !== chat.agent.uid));
                if ((_a = chat === null || chat === void 0 ? void 0 : chat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) {
                    return INPUT_BLOCK.BLOCKED;
                }
                else if (!acceptChatCondition && sessionIsOver) {
                    return INPUT_BLOCK.SESSION_OVER;
                }
                else if (!acceptChatCondition && chat && !sessionIsOver && !filePreview && !sendLocationCoords) {
                    return INPUT_BLOCK.COMPOSE;
                }
                else if (acceptChatCondition) {
                    return INPUT_BLOCK.ACCEPT;
                }
                return null;
            }
        });
        Object.defineProperty(this, "renderHelperScreens", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat, chatAccess, chatLoading } = this.props.store.activeChatStore;
                if (!chat) {
                    if (chatLoading) {
                        return (React.createElement("div", { className: styles.loadingWrapper },
                            React.createElement(LoadingScreen, null)));
                    }
                    if (!chatAccess || (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.view)) {
                        return React.createElement(NoActiveChatSelected, { bottomBlock: true });
                    }
                    else {
                        return React.createElement(NoChatViewPermission, null);
                    }
                }
                return null;
            }
        });
        Object.defineProperty(this, "onSendLocationClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        event: "getLocationCoords",
                    }));
                }
                else {
                    return new Promise((resolve) => {
                        const successCallback = ({ coords }) => {
                            this.props.store.activeChatStore.setSendLocationCoords({
                                latitude: coords.latitude,
                                longitude: coords.longitude,
                            });
                            resolve();
                        };
                        const errorCallback = (error) => {
                            message.error('Location access denied');
                            console.error(error);
                            resolve();
                        };
                        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
                    });
                }
            })
        });
        this.state = {
            quickReply: "",
            composeText: "",
            defaultTemplateText: "",
            currentChatId: "",
            onBottom: false,
            barToDisplay: INPUT_BLOCK.ACCEPT,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { activeChatStore } = this.props.store;
        if (activeChatStore.chat && this.state.currentChatId !== activeChatStore.chat._id) {
            this.setState({
                quickReply: "",
                composeText: "",
                defaultTemplateText: "",
                currentChatId: activeChatStore.chat._id,
            });
            activeChatStore.toggleFilePreview(false);
            this.props.store.templateMessagesStore.setSelectedAccount({
                id: activeChatStore.chat.channelInfo.accountId,
                name: activeChatStore.chat.channelInfo.name,
            });
        }
        if (prevState.barToDisplay !== this.inputBlockToShow()) {
            const newBar = this.inputBlockToShow();
            if (newBar) {
                this.setState({ barToDisplay: newBar });
            }
        }
    }
    render() {
        var _a;
        const { activeChatStore, channels, agentsStore } = this.props.store;
        const { chat: activeChat, chatAccess, filePreview, theme, activeReplyMessage, defaultTemplate, sendLocationMessage, sendLocationCoords, setSendLocationCoords, } = activeChatStore;
        const { hasFeatures } = channels;
        const { composeText, defaultTemplateText } = this.state;
        const blockToShow = this.inputBlockToShow();
        const allowPaste = hasFeatures([MessagingChannelFeature.attachments]) && blockToShow === INPUT_BLOCK.COMPOSE;
        const isWhatsappChannel = ((_a = activeChat === null || activeChat === void 0 ? void 0 : activeChat.channelInfo) === null || _a === void 0 ? void 0 : _a.name) === "whatsapp";
        const disabledMessage = (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.send) ? null : 'noChatSendPermission';
        const showQuickReplies = !filePreview && !sendLocationCoords;
        return (React.createElement("div", { className: classnames(styles.messenger, { [styles.chatOpen]: activeChat !== null }) },
            !activeChat && window.innerWidth <= MOBILE_WIDTH ? (React.createElement("div", { className: classnames(styles.scrollable, styles.sidebar) },
                React.createElement(ErrorBoundary, null,
                    React.createElement(ChatsList, null)))) : window.innerWidth <= MOBILE_WIDTH ? (React.createElement("div", { style: { backgroundImage: this.handleBackgrounds() }, className: classnames(styles.scrollable, styles.content, { [styles.chatOpenMobile]: activeChat !== null }), onPaste: (e) => this.handlePaste(e, allowPaste) },
                !!activeChat &&
                    React.createElement(UserInfo, { activeChat: activeChat }),
                this.renderHelperScreens(),
                React.createElement("div", { className: classnames(styles.messageListNoDropContainer, {
                        [styles.filePreview]: filePreview,
                        [styles.replyOpen]: !!activeReplyMessage,
                        [styles.withDefaultTemplate]: defaultTemplate && (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.send),
                    }) },
                    !!activeChat && (React.createElement(ErrorBoundary, null,
                        React.createElement(MessagesList, { reference: (messagesList) => (this.messagesList = messagesList), onBottomStateChange: (onBottom) => this.setState({ onBottom }) }))),
                    !this.state.onBottom && activeChat && (React.createElement("div", { className: styles.scrollToUnreadAnchor },
                        React.createElement(ScrollToUnreadButton, { handleClick: activeChat.unreadCount > 0 ? this.scrollToUnread : this.scrollToBottom, unreadCount: activeChat.unreadCount })))),
                !!activeChat && (React.createElement("div", { className: styles.lowerBlock },
                    !!activeChat.replyAction && React.createElement(ReplyPreview, null),
                    blockToShow === INPUT_BLOCK.ACCEPT && (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) && React.createElement(AcceptChatBlock, { activeChat: activeChat }),
                    blockToShow === INPUT_BLOCK.SESSION_OVER && React.createElement(SessionIsOverBlock, null),
                    blockToShow === INPUT_BLOCK.SESSION_OVER && defaultTemplate && isWhatsappChannel && (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.send) && (React.createElement(DefaultTemplateReplyBar, { value: defaultTemplateText, onChange: (value) => this.setState({ defaultTemplateText: value }) })),
                    blockToShow === INPUT_BLOCK.COMPOSE && (React.createElement(Compose, { composeText: composeText, onChange: this.onComposeTextChange, onFileDrop: this.onFileDrop, sendTextMessageCB: this.sendTextMessage, activeChat: activeChat, onQuickReplySelected: this.onQuickReplySelected, onSendLocationClick: this.onSendLocationClick, quickReplyText: this.state.quickReply, currentChatId: this.state.currentChatId, theme: theme, disabled: !!disabledMessage, disabledMessage: disabledMessage, beforeUpload: this.beforeUpload })),
                    blockToShow === INPUT_BLOCK.BLOCKED && React.createElement(BlockedChatBar, null),
                    showQuickReplies && agentsStore.hasPermissions([Permissions.Permission.quick_replies_view]) && (React.createElement(QuickReplies, { onQuickReplySelected: this.onQuickReplySelected })))),
                sendLocationCoords && (React.createElement(SendLocationWindow, { coords: sendLocationCoords, onCancel: () => setSendLocationCoords(null), onSend: () => {
                        sendLocationMessage(sendLocationCoords);
                        setSendLocationCoords(null);
                    } })),
                React.createElement(FilePreview, { activeChat: activeChat, composeProps: { composeText, onChange: this.onComposeTextChange } }))) : null,
            window.innerWidth > MOBILE_WIDTH ? (React.createElement("div", { className: classnames(styles.scrollable, styles.sidebar) },
                React.createElement(ErrorBoundary, null,
                    React.createElement(ChatsList, null)))) : null,
            window.innerWidth > MOBILE_WIDTH ? (React.createElement("div", { style: { backgroundImage: this.handleBackgrounds() }, className: classnames(styles.scrollable, styles.content), onPaste: (e) => this.handlePaste(e, allowPaste) },
                !!activeChat &&
                    React.createElement(UserInfo, { activeChat: activeChat }),
                this.renderHelperScreens(),
                React.createElement(DragAndDrop, { handleDrop: this.onFileDrop, active: this.inputBlockToShow() === INPUT_BLOCK.COMPOSE, isReplyOpen: !!activeReplyMessage },
                    !!activeChat && (React.createElement(ErrorBoundary, null,
                        React.createElement(MessagesList, { reference: (messagesList) => (this.messagesList = messagesList), onBottomStateChange: (onBottom) => this.setState({ onBottom }) }))),
                    !this.state.onBottom && activeChat && (React.createElement("div", { className: styles.scrollToUnreadAnchor },
                        React.createElement(ScrollToUnreadButton, { handleClick: activeChat.unreadCount > 0 ? this.scrollToUnread : this.scrollToBottom, unreadCount: activeChat.unreadCount }))),
                    sendLocationCoords && (React.createElement(SendLocationWindow, { coords: sendLocationCoords, onCancel: () => setSendLocationCoords(null), onSend: () => {
                            sendLocationMessage(sendLocationCoords);
                            setSendLocationCoords(null);
                        } })),
                    React.createElement(FilePreview, { activeChat: activeChat, composeProps: { composeText, onChange: this.onComposeTextChange } })),
                !!activeChat && (React.createElement("div", { className: styles.lowerBlock },
                    !!activeChat.replyAction && React.createElement(ReplyPreview, null),
                    blockToShow === INPUT_BLOCK.ACCEPT && (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) && React.createElement(AcceptChatBlock, { activeChat: activeChat }),
                    blockToShow === INPUT_BLOCK.SESSION_OVER && React.createElement(SessionIsOverBlock, null),
                    blockToShow === INPUT_BLOCK.SESSION_OVER && defaultTemplate && isWhatsappChannel && (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.send) && (React.createElement(DefaultTemplateReplyBar, { value: defaultTemplateText, onChange: (value) => this.setState({ defaultTemplateText: value }) })),
                    blockToShow === INPUT_BLOCK.COMPOSE && (React.createElement(Compose, { composeText: composeText, onChange: this.onComposeTextChange, onFileDrop: this.onFileDrop, sendTextMessageCB: this.sendTextMessage, activeChat: activeChat, onQuickReplySelected: this.onQuickReplySelected, onSendLocationClick: this.onSendLocationClick, quickReplyText: this.state.quickReply, currentChatId: this.state.currentChatId, theme: theme, disabled: !!disabledMessage, disabledMessage: disabledMessage, beforeUpload: this.beforeUpload })),
                    blockToShow === INPUT_BLOCK.BLOCKED && React.createElement(BlockedChatBar, null),
                    showQuickReplies && agentsStore.hasPermissions([Permissions.Permission.quick_replies_view]) && (React.createElement(QuickReplies, { onQuickReplySelected: this.onQuickReplySelected })))))) : null));
    }
}
export default withStore(observer(Messenger));
