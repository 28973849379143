import React from 'react';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import moment from 'moment';
import { Tooltip } from 'antd';
import s from './ReactionMessage.module.scss';
const ReactionMessage = ({ reactions, history, direction }) => {
    if (reactions && reactions.length === 0)
        return null;
    const latestReaction = reactions === null || reactions === void 0 ? void 0 : reactions[reactions.length - 1];
    const latestTimestamp = history === null || history === void 0 ? void 0 : history[history.length - 1].timestamp;
    return (React.createElement("div", { className: classNames(s.container, {
            [s.in]: direction === MESSAGE_DIRECTION.IN,
        }) },
        React.createElement("div", { className: s.content },
            React.createElement(Tooltip, { title: moment(latestTimestamp).fromNow() },
                React.createElement("span", null, latestReaction)))));
};
export default ReactionMessage;
