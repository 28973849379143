import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import React from 'react';
import MessageMarkdown from '../../../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import styles from './TextPostback.module.scss';
const TextPostback = ({ context, name, type, incoming, isPostback, invertChatColor }) => {
    const text = isPostback ? context.postback.title || context.postback.payload : context.text;
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
        }) },
        React.createElement("p", { className: classNames(styles.name, {
                [styles.incoming]: incoming,
                [styles.invertColors]: !incoming && invertChatColor && type === 'message',
            }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
        React.createElement("p", { className: classNames(styles.text, {
                [styles.reply]: type === 'reply',
                [styles.invertColors]: !incoming && type === 'message',
            }) },
            React.createElement(MessageMarkdown, { text: text }))));
};
export default TextPostback;
