import React, { Component, createRef } from "react";
import { observer } from "mobx-react";
import { Checkbox, Tooltip } from "antd";
import { Trans, withTranslation } from "react-i18next";
import { DatePicker, TimePicker, Form } from "antd";
import Icon from "@ant-design/icons";
import { RiMailCloseLine } from "react-icons/ri";
import moment from "moment";
import classnames from "classnames";
import hebrewCheck from "../../../Base/Utils/HebrewCheck";
import { withStore } from "../../../../store/rootStore";
import blankProfilePicture from "../../../../assets/avatars/blankProfilePicture.png";
import TemplateTitle from "../TemplateTitle/TemplateTitle";
import CSVNumbersList from "./CSVNumbersList";
import { getDirection } from "../../../../helpers";
import styles from "./FourthPage.module.scss";
const timePickerFormat = "HH:mm";
class FourthPage extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "scheduleBoxRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onTermsCheckboxClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.store.wizardStore.setTermsAgreement();
            }
        });
        Object.defineProperty(this, "onScheduleEnable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (enabled) => {
                const { wizardStore } = this.props.store;
                wizardStore.setScheduled(enabled);
                wizardStore.setScheduledOn(enabled ? this.state.scheduledOn.unix() : undefined);
                if (enabled) {
                    setTimeout(() => {
                        var _a;
                        (_a = this.scheduleBoxRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth" });
                    }, 100);
                }
            }
        });
        Object.defineProperty(this, "onDatePickerChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (momentVal) => {
                if (!momentVal)
                    return;
                const now = moment();
                if (momentVal.isBefore(now)) {
                    now.hour(now.hour() + 1);
                    momentVal = now;
                }
                this.setState({ scheduledOn: momentVal }, () => {
                    this.props.store.wizardStore.setScheduledOn(this.state.scheduledOn.unix());
                });
            }
        });
        Object.defineProperty(this, "onTimeSelect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (momentVal) => {
                this.setState({ scheduledOn: momentVal }, () => {
                    this.props.store.wizardStore.setScheduledOn(this.state.scheduledOn.unix());
                });
            }
        });
        Object.defineProperty(this, "renderUnsubscribedTooltip", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { unsubscribedChats } = this.props.store.wizardStore;
                return (React.createElement("div", { className: styles.tooltipContainer }, unsubscribedChats.map(({ title, _id }) => (React.createElement("div", { className: styles.tooltipRow, key: _id },
                    React.createElement("img", { src: blankProfilePicture, className: styles.tooltipAvatar }),
                    React.createElement("div", { className: styles.tooltipTitle }, title))))));
            }
        });
        this.scheduleBoxRef = createRef();
        this.state = {
            // By default tomorrow at 08:00
            scheduledOn: moment().add(1, "days").hours(8).minutes(0).seconds(0),
        };
    }
    componentDidMount() {
        this.props.store.wizardStore.setClientsAmount();
        this.props.store.wizardStore.getUnsubscribedChats();
    }
    render() {
        var _a;
        const { store, i18n } = this.props;
        const { clientsAmount, unsubscribedChatsAmount, scheduled, termsAgreement, chosenTemplate } = store.wizardStore;
        const scheduleEnabled = !!((_a = store.generalSettings.features) === null || _a === void 0 ? void 0 : _a.databaseTasks);
        const currentDayBegin = moment().hours(0).minutes(0).seconds(1);
        const oneMonthLater = moment().add(1, "months");
        return (React.createElement("div", { className: classnames(styles.csvSendMain, {
                [styles.rtl]: getDirection() === 'rtl',
            }) },
            React.createElement(TemplateTitle, { title: (chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.title) || '', name: (chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.name) || '', isRtl: getDirection() === 'rtl' }),
            React.createElement("div", { className: styles.csvSendInfoMain },
                React.createElement("div", { className: styles.extraInfoContainer },
                    React.createElement("div", { className: classnames(styles.csvSendInfoBlock, { [styles.reversed]: hebrewCheck('', i18n === null || i18n === void 0 ? void 0 : i18n.language) }) },
                        React.createElement("p", { className: styles.csvInfoTitle },
                            React.createElement(Trans, { i18nKey: "goingToSendTemplate" })),
                        React.createElement("div", { className: styles.dottedLine }),
                        React.createElement("p", { className: styles.csvInfoText },
                            clientsAmount,
                            " ",
                            React.createElement(Trans, { i18nKey: "usersFourthPage" }))),
                    unsubscribedChatsAmount > 0 ? (React.createElement("div", { className: classnames(styles.csvSendInfoBlock, { [styles.reversed]: hebrewCheck('', i18n === null || i18n === void 0 ? void 0 : i18n.language) }) },
                        React.createElement("p", { className: styles.csvInfoTitle },
                            React.createElement(Trans, { i18nKey: "unsubscribed" })),
                        React.createElement("div", { className: styles.dottedLine }),
                        React.createElement("p", { className: styles.csvInfoText },
                            React.createElement(Tooltip, { title: this.renderUnsubscribedTooltip(), placement: "right", overlayStyle: { minWidth: 200 } },
                                React.createElement(Icon, { className: styles.unsubscribedIcon, component: RiMailCloseLine })),
                            unsubscribedChatsAmount,
                            " ",
                            React.createElement(Trans, { i18nKey: "usersFourthPage" })))) : null,
                    React.createElement(CSVNumbersList, null)),
                React.createElement("div", { className: styles.actions },
                    scheduleEnabled && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.checkboxWrap, ref: this.scheduleBoxRef },
                            React.createElement(Checkbox, { checked: scheduled, onChange: (e) => this.onScheduleEnable(e.target.checked) },
                                React.createElement(Trans, { i18nKey: "scheduleDelayedSend" }))),
                        scheduled && (React.createElement(Form, { size: "large" },
                            React.createElement(Form.Item, { label: "Date" },
                                React.createElement(DatePicker, { size: "large", allowClear: false, value: this.state.scheduledOn, onChange: (date) => this.onDatePickerChange(date), disabledDate: (date) => {
                                        return date.isBefore(currentDayBegin) || date.isAfter(oneMonthLater);
                                    } })),
                            React.createElement(Form.Item, { label: "Time" },
                                React.createElement(TimePicker, { size: "large", allowClear: false, value: this.state.scheduledOn, format: timePickerFormat, minuteStep: 10, showNow: false, onSelect: (val) => this.onTimeSelect(val), disabledTime: () => {
                                        return {
                                            disabledHours: () => {
                                                const disabled = [];
                                                if (this.state.scheduledOn.isSame(currentDayBegin, "date")) {
                                                    const hourNow = moment().hour();
                                                    for (let h = 0; h <= hourNow; h++) {
                                                        disabled.push(h);
                                                    }
                                                }
                                                return disabled;
                                            },
                                        };
                                    }, hideDisabledOptions: true, inputReadOnly: true })))))),
                    React.createElement("div", { className: styles.checkboxWrap },
                        React.createElement(Checkbox, { checked: termsAgreement, onChange: this.onTermsCheckboxClick },
                            React.createElement(Trans, { i18nKey: "agreeWithWhatsappTerms", components: {
                                    1: (React.createElement("a", { className: styles.whatsappTerms, href: "https://www.whatsapp.com/legal/client", target: "_blank" })),
                                } })))))));
    }
}
export default withTranslation()(withStore(observer(FourthPage)));
