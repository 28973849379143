import React from 'react';
import { Typography } from "antd";
import { Trans } from "react-i18next";
import blankProfilePicture from "../../../../assets/avatars/blankProfilePicture.png";
import styles from "./NoContact.module.scss";
const { Text } = Typography;
const NoContact = () => {
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("img", { className: styles.photo, src: blankProfilePicture }),
        React.createElement(Text, { className: styles.title },
            React.createElement(Trans, { i18nKey: "noContactInCrm" })),
        React.createElement(Text, { className: styles.text },
            React.createElement(Trans, { i18nKey: "noContactFoundInCrm" }))));
};
export default NoContact;
