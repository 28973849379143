import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Typography } from "antd";
import Icon from "@ant-design/icons";
import classnames from "classnames";
import { Trans, withTranslation } from "react-i18next";
import Button from "antd/es/button";
import { Permissions, WhatsApp } from '@whatsper/texterchat-common';
import SingleTemplate from "../SingleTemplate";
import { ReactComponent as QuickRepliesFolder } from "../../../../../assets/icons/file/folderIcon.svg";
import { ReactComponent as QuickRepliesPlus } from "../../../../../assets/icons/plus/quickRepliesPlus.svg";
import CommonSwitch from "../../../common/CommonSwitch";
import { withStore } from '../../../../../store/rootStore';
import ErrorBoundary from '../../../../ErrorBoundary';
import { getDirection } from '../../../../../helpers';
import Filters from '../../../../Templates/Filters';
import { TEMPLATE_SORT } from '../../../../../constants';
import { filterByName, filterTemplates, sortByDateCreated, sortByLastUsed, sortByName } from '../../../../Templates/TemplateListFilterable/helpers';
import styles from "./TemplatesList.module.scss";
const { Text } = Typography;
class TemplatesList extends Component {
    constructor(props) {
        var _a;
        super(props);
        Object.defineProperty(this, "sorterMap", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                [TEMPLATE_SORT.NAME]: sortByName,
                [TEMPLATE_SORT.DATE_CREATED]: sortByDateCreated,
                [TEMPLATE_SORT.LAST_USED]: sortByLastUsed(this.props.store.templateMessagesStore.templateMessagesLists, ((_a = this.props.store.templateMessagesStore.selectedAccountId) === null || _a === void 0 ? void 0 : _a.id) || ''),
            }
        });
        Object.defineProperty(this, "filteredTemplates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { sort, searchValue, department, showDeleted } = this.state;
                const { templateMessagesList } = this.props.store.templateMessagesStore;
                return templateMessagesList
                    .filter(filterTemplates({
                    onlyStatus: [
                        WhatsApp.TemplateLocalizationStatus.APPROVED,
                        WhatsApp.TemplateLocalizationStatus.PENDING,
                        WhatsApp.TemplateLocalizationStatus.REQUESTED,
                        WhatsApp.TemplateLocalizationStatus.SUBMITTED,
                        ...showDeleted ? [
                            WhatsApp.TemplateLocalizationStatus.DELETED,
                            WhatsApp.TemplateLocalizationStatus.DELETION_PENDING,
                            WhatsApp.TemplateLocalizationStatus.REJECTED,
                            WhatsApp.TemplateLocalizationStatus.SUBMIT_FAILED,
                        ] : [],
                    ],
                    department,
                }))
                    .filter(filterByName(searchValue))
                    .sort(this.sorterMap[sort]);
            }
        });
        Object.defineProperty(this, "onTemplateClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (template) => {
                const { setTemplateAnchor, store: { templateMessagesStore: { setTemplateEdited } } } = this.props;
                setTemplateAnchor(template.name);
                setTemplateEdited(template);
            }
        });
        this.state = {
            showDeleted: false,
            department: null,
            sort: TEMPLATE_SORT.LAST_USED,
            searchValue: '',
        };
    }
    render() {
        const { templateMessagesStore, agentsStore } = this.props.store;
        const { setTemplateEdited, setTemplateDeleting, setNewTemplate, templateMessagesLoading, } = templateMessagesStore, { showDeleted, sort, department, searchValue } = this.state;
        const { i18n, forwardRef } = this.props;
        const templatesFiltered = this.filteredTemplates();
        const canManageTemplates = agentsStore.hasPermissions([Permissions.Permission.template_messages_manage]);
        return (React.createElement("div", { className: styles.bodyWrapper },
            React.createElement(Filters, { department: department, onDepartmentChanged: department => this.setState({ department }), sort: sort, onSortChanged: sort => this.setState({ sort }), searchValue: searchValue, onSearchChanged: searchValue => this.setState({ searchValue }), disabled: templateMessagesLoading, i18n: i18n, isLessThanDesktop: true }),
            React.createElement("div", { className: styles.existingTemplates, ref: forwardRef },
                this.props.header,
                React.createElement("div", { className: styles.showDeletedBlock },
                    React.createElement(Text, null,
                        React.createElement(Trans, { i18nKey: "showDeletedAndRejected" })),
                    React.createElement(CommonSwitch, { checked: showDeleted, onChange: () => {
                            this.setState((state) => ({ showDeleted: !state.showDeleted }));
                        } })),
                templatesFiltered.length ?
                    templatesFiltered.map((template, i) => React.createElement(ErrorBoundary, { key: i },
                        React.createElement(SingleTemplate, { template: template, templateText: template.text, setTemplateEdited: this.onTemplateClick, setTemplateDeleting: setTemplateDeleting, isRTL: getDirection() === "rtl", canManageTemplates: canManageTemplates }))) : (React.createElement("div", { className: styles.noTemplatesContainer },
                    React.createElement("div", { className: styles.noTemplatesBody },
                        React.createElement(Icon, { className: styles.noTemplatesIcon, component: QuickRepliesFolder }),
                        React.createElement("div", { className: styles.noTemplatesBodyText },
                            React.createElement(Text, { strong: true },
                                React.createElement(Trans, { i18nKey: "didntCreateTemplates" }))))))),
            canManageTemplates && (React.createElement("div", { className: styles.templatesBottom },
                React.createElement(Button, { className: classnames("big", styles.createNewTemplateButton), onClick: setNewTemplate, disabled: templateMessagesLoading },
                    React.createElement(Icon, { component: QuickRepliesPlus }),
                    React.createElement(Text, { strong: true },
                        React.createElement(Trans, { i18nKey: "createNewTemplateMessage" })))))));
    }
}
export default withTranslation()(withStore(observer(TemplatesList)));
