var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { runInAction, when } from 'mobx';
import { observer } from "mobx-react";
import VisibilitySensor from "react-visibility-sensor";
import { Trans } from "react-i18next";
import { Spin, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { throttle } from 'lodash';
import PrettyDateBox from "../PrettyDateBox";
import Message from '../MessagesTypes/Message';
import ContactListSelector from "../common/ContactListSelector";
import LoadingScreen from "../../LoadingScreen";
import { MESSAGE_STATUS } from '../../../constants';
import SimpleModal from "../common/SimpleModal";
import { withStore } from '../../../store/rootStore';
import LazyLoadList, { LoadDirection } from '../../Base/Utils/LazyLoadList';
import { MessagesLoading } from '../../../store/ActiveChatStore';
import PrettyDateBoxStatic from '../PrettyDateBox/PrettyDateBoxStatic';
import { isContactsMessage, isMediaMessage, isTextMessage } from '@whatsper/texterchat-common';
import styles from './MessagesList.module.scss';
class MessagesList extends Component {
    constructor(props) {
        super(props);
        // Unfortunatelly just comparing value in mobx store 
        // between previous and new props doesn't work,
        // since store always up to date in prev props
        Object.defineProperty(this, "prevChatId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "prevMessageId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "prevLastMessageId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "messagesEndRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unreadMessageRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "scrollToMessageRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rootRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loadWaitDispose", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "scrollTimeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                if (this.props.reference) {
                    this.props.reference(this);
                }
                this.scrollToTarget();
                (_a = this.rootRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', this.onScrollListener);
            }
        });
        Object.defineProperty(this, "componentWillUnmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                if (this.loadWaitDispose) {
                    this.loadWaitDispose();
                }
                if (this.props.reference) {
                    this.props.reference(null);
                }
                if (this.scrollTimeout) {
                    clearTimeout(this.scrollTimeout);
                }
                (_a = this.rootRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', this.onScrollListener);
            }
        });
        Object.defineProperty(this, "onScrollListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(() => {
                const { currentDateLabel, isScrolling } = this.state;
                const { messagesLoading } = this.props.store.activeChatStore;
                const container = this.rootRef.current;
                if (!container)
                    return;
                if (messagesLoading === MessagesLoading.ALL)
                    return;
                // @ts-ignore Should be 'null' instead of any
                let newLabel = null;
                if (!isScrolling) {
                    this.setState({ isScrolling: true });
                }
                const dateLabels = container.querySelectorAll(".date-label");
                dateLabels.forEach((dateLabel) => {
                    if (container.scrollTop >= dateLabel.offsetTop) {
                        newLabel = dateLabel;
                    }
                });
                if (newLabel && newLabel.innerText !== currentDateLabel) {
                    this.setState({ currentDateLabel: newLabel.innerText });
                }
                if (this.scrollTimeout) {
                    clearTimeout(this.scrollTimeout);
                }
                this.scrollTimeout = setTimeout(() => {
                    this.setState({ isScrolling: false });
                }, 2000);
            }, 100)
        });
        Object.defineProperty(this, "scrollToTarget", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.props.store.activeChatStore.messageId) {
                    this.scrollToMessageId();
                }
                else {
                    this.scrollToBottom();
                }
            }
        });
        /**
         * @todo
         *   Make it work with new pagination system.
         *   For now it disabled because have unpredictable results,
         *   because some of unread messages can be not loaded
         */
        Object.defineProperty(this, "scrollToUnread", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.scrollToBottom();
                // this.whenLoadEnd(() => {
                //     if(this.unreadMessageRef.current){
                //         this.unreadMessageRef.current.scrollIntoView();
                //     } else {
                //         // Seems unread messages in some unloaded part
                //         // so scroll to bottom instead
                //         this.scrollToBottom();
                //     }
                // });
            }
        });
        Object.defineProperty(this, "scrollToBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.whenLoadEnd(() => {
                    setTimeout(() => {
                        const { activeChatStore } = this.props.store;
                        // Has next messages so need to load before scroll
                        if (activeChatStore.hasNextMessages) {
                            activeChatStore
                                .loadMessages(true)
                                .then(() => {
                                var _a;
                                (_a = this.messagesEndRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
                            });
                        }
                        else if (this.messagesEndRef.current) {
                            this.messagesEndRef.current.scrollIntoView();
                        }
                        // Pure magic, but otherwise do not work
                    }, 0);
                });
            }
        });
        /** Most likely this not need anymore, because messages already loaded from target ID */
        Object.defineProperty(this, "scrollToMessageId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.whenLoadEnd(() => {
                    setTimeout(() => {
                        if (this.scrollToMessageRef.current) {
                            this.scrollToMessageRef.current.scrollIntoView(false); // Align to bottom
                        }
                    }, 0);
                });
            }
        });
        /** Wait messages loading end */
        Object.defineProperty(this, "whenLoadEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                this.loadWaitDispose = when(() => this.props.store.activeChatStore.messagesLoading === MessagesLoading.NONE, () => {
                    this.loadWaitDispose = null;
                    cb();
                });
            }
        });
        Object.defineProperty(this, "handleChangeUnreadToRead", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (isVisible, message) => {
                if (isVisible) {
                    this.props.store.activeChatStore.setMessagesRead([message]);
                }
            }
        });
        Object.defineProperty(this, "checkFirstUnreadMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messages, key) => {
                if (messages[key].status !== MESSAGE_STATUS.MESSAGE_SEEN && key > 0 && messages[key - 1].status === MESSAGE_STATUS.MESSAGE_SEEN) {
                    return true;
                }
            }
        });
        Object.defineProperty(this, "checkIfOnBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (isVisible) => {
                if (isVisible) {
                    this.setOnBottom(true);
                    this.props.store.activeChatStore.setMessagesRead(true);
                }
                else {
                    this.setOnBottom(false);
                }
            }
        });
        Object.defineProperty(this, "setOnBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (onBottom) => {
                this.setState({ onBottom }, () => {
                    if (typeof this.props.onBottomStateChange === 'function') {
                        this.props.onBottomStateChange(onBottom);
                    }
                });
            }
        });
        Object.defineProperty(this, "checkTimeDiffWithPrevMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messages, key) => {
                if (messages[key - 1]) {
                    let timeDiff = messages[key].received - messages[key - 1].received;
                    let previousDay = new Date(messages[key - 1].received).getDate();
                    let currentDay = new Date(messages[key].received).getDate();
                    if (timeDiff > 1000 * 60 * 60 * 24 || previousDay !== currentDay) {
                        return true;
                    }
                    else
                        return false;
                }
                return false;
            }
        });
        Object.defineProperty(this, "highlightMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messageId) => {
                this.setState({ highlightedMessageId: messageId });
                setTimeout(() => {
                    this.setState({ highlightedMessageId: null });
                }, 1000);
            }
        });
        /**
         * Scroll to message of specified ID, optionally loding it
         *
         * @param id ID of message to scroll into view
         */
        Object.defineProperty(this, "scrollToMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                const hasScrolled = this.scrollToId(id);
                if (!hasScrolled) {
                    // Seems outside of loaded list, so load with message
                    this.props.store.activeChatStore.loadMessages(true, false, id)
                        .then(() => {
                        runInAction(() => {
                            this.props.store.activeChatStore.messageId = id;
                            this.scrollToId(id);
                        });
                    })
                        .catch((error) => {
                        console.error(error);
                    });
                }
            }
        });
        Object.defineProperty(this, "scrollToId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                const anchor = document.getElementById(`message-${id}`);
                if (anchor) {
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    this.highlightMessage(id);
                    return true;
                }
                return false;
            }
        });
        Object.defineProperty(this, "handleForwardMessageOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ forwardMenuOpen: !this.state.forwardMenuOpen });
            }
        });
        Object.defineProperty(this, "listUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (list) => {
                this.setState({ temporarySelectedContactsList: list });
            }
        });
        Object.defineProperty(this, "addToSelectedMessagesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messageId, multichoise) => {
                const temporarySelectedMessages = this.state.temporarySelectedMessages;
                if (temporarySelectedMessages.includes(messageId) && multichoise) {
                    const newList = temporarySelectedMessages.filter((item) => item !== messageId);
                    this.setState({ temporarySelectedMessages: newList });
                }
                else if (multichoise) {
                    this.setState({ temporarySelectedMessages: [...this.state.temporarySelectedMessages, messageId] });
                }
                else if (!multichoise) {
                    this.setState({ temporarySelectedMessages: [messageId] });
                }
                this.handleForwardMessageOpen();
            }
        });
        Object.defineProperty(this, "contactListSelector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return (React.createElement(ContactListSelector, { onlyActiveChats: true, multichoice: false, listUpdated: this.listUpdated, selectedContacts: this.state.temporarySelectedContactsList }));
            }
        });
        Object.defineProperty(this, "forwardMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { activeChatStore, messages: messagesStore } = this.props.store;
                const { messages } = activeChatStore;
                const selectedMessages = this.state.temporarySelectedMessages;
                const contactsList = this.state.temporarySelectedContactsList;
                const filteredMessages = messages.filter((message) => {
                    return selectedMessages.includes(message._id);
                });
                return Promise.all(contactsList.map((chatId) => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    const toCurrent = chatId === (((_a = activeChatStore.chat) === null || _a === void 0 ? void 0 : _a._id) || null);
                    return Promise.all(filteredMessages.map((message) => {
                        if (isTextMessage(message)) {
                            const { text } = message;
                            if (text) {
                                if (toCurrent) {
                                    return activeChatStore.sendTextMessage(text);
                                }
                                else {
                                    return messagesStore.sendTextMessage(chatId, text);
                                }
                            }
                        }
                        else if (isMediaMessage(message)) {
                            const { media } = message;
                            const file = media[0];
                            if (file) {
                                if (file.fileId) {
                                    if (toCurrent) {
                                        return activeChatStore.sendMediaMessage(file.fileId, file.mediaType, file.filename || '', file.caption);
                                    }
                                    else {
                                        return messagesStore.sendMediaMessage(chatId, file.fileId, file.mediaType, file.filename, file.caption);
                                    }
                                }
                                else {
                                    // TODO: Old files migration script
                                    console.warn('Can not forward old format message!');
                                    return Promise.resolve();
                                }
                            }
                        }
                        else if (isContactsMessage(message)) {
                            const { contacts } = message;
                            if (contacts) {
                                if (toCurrent) {
                                    return activeChatStore.sendContactsMessage(contacts);
                                }
                                else {
                                    return messagesStore.sendContactsMessage(chatId, contacts);
                                }
                            }
                        }
                        return null;
                    }));
                }))).then(() => {
                    this.setState({ temporarySelectedContactsList: [], temporarySelectedMessages: [] });
                });
            })
        });
        Object.defineProperty(this, "renderMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat, messages, messageId } = this.props.store.activeChatStore;
                const { highlightedMessageId } = this.state;
                return messages.map((message, i) => {
                    const prevMessage = messages[i - 1];
                    const currentMessage = message;
                    return (React.createElement("div", { key: message._id, id: `message-${message._id}` },
                        this.checkTimeDiffWithPrevMessage(messages, i) ? (React.createElement(PrettyDateBox, { messages: messages, index: i })) : null,
                        i === 0 ? React.createElement(PrettyDateBox, { messages: messages, index: i }) : null,
                        React.createElement(VisibilitySensor, { onChange: (isVisible) => this.handleChangeUnreadToRead(isVisible, message) },
                            React.createElement(Message, { message: currentMessage, activeChat: chat, previousMessage: prevMessage, name: (chat === null || chat === void 0 ? void 0 : chat.title) || '', scrollToMessage: this.scrollToMessage, handleForwardMessage: () => this.addToSelectedMessagesList(message._id, false), isHighlighted: highlightedMessageId === message._id })),
                        messageId && messageId === message._id ? React.createElement("div", { ref: this.scrollToMessageRef }) : null,
                        this.checkFirstUnreadMessage(messages, i) ? React.createElement("div", { ref: this.unreadMessageRef }) : null));
                });
            }
        });
        Object.defineProperty(this, "renderMessagesLoader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (dir) => {
                const wrapStyle = {};
                if (dir === LoadDirection.PREV) {
                    wrapStyle.top = '20px';
                }
                else {
                    wrapStyle.bottom = '50px';
                }
                return (React.createElement("div", { className: styles.loaderWrap, style: wrapStyle },
                    React.createElement(Spin, { className: styles.loader, indicator: React.createElement(LoadingOutlined, { style: { fontSize: 28, color: 'gray' }, spin: true }) })));
            }
        });
        Object.defineProperty(this, "renderForwardMessagesModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return (React.createElement(SimpleModal, { visible: this.state.forwardMenuOpen, setModalVisible: () => {
                        this.handleForwardMessageOpen();
                        this.listUpdated([]);
                    }, titleTextKey: "forwardMessageTo", buttons: React.createElement(React.Fragment, null,
                        React.createElement(Button, { className: styles.forwardMessageButton, onClick: () => this.setState({ forwardMenuOpen: false }) },
                            React.createElement(Trans, { i18nKey: "cancel" })),
                        React.createElement(Button, { disabled: !this.state.temporarySelectedContactsList.length, className: styles.forwardMessageButton, type: "primary", onClick: () => {
                                this.handleForwardMessageOpen();
                                this.forwardMessages();
                            } },
                            React.createElement(Trans, { i18nKey: "forward" }))) }, this.contactListSelector()));
            }
        });
        this.state = {
            onBottom: false,
            chatLength: 0,
            temporarySelectedMessages: [],
            temporarySelectedContactsList: [],
            forwardMenuOpen: false,
            isScrolling: false,
            currentDateLabel: '',
            highlightedMessageId: null,
        };
        this.messagesEndRef = React.createRef();
        this.unreadMessageRef = React.createRef();
        this.scrollToMessageRef = React.createRef();
        this.rootRef = React.createRef();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { messageId, chat, messages, hasNextMessages } = this.props.store.activeChatStore;
        const lastMessageId = messages.length ? messages[messages.length - 1]._id : null;
        if ( // Another chat
        (chat === null || chat === void 0 ? void 0 : chat._id) !== this.prevChatId
            // Another target message
            || (messageId && messageId !== this.prevMessageId)
            // At the end of list and new messages added to the end
            || (!hasNextMessages && lastMessageId && lastMessageId !== this.prevLastMessageId)) {
            this.scrollToTarget();
        }
        this.prevChatId = chat ? chat._id : null;
        this.prevMessageId = messageId;
        this.prevLastMessageId = lastMessageId;
    }
    render() {
        const { messagesLoading, hasNextMessages, hasPrevMessages, loadMoreMessages, messages } = this.props.store.activeChatStore;
        const { currentDateLabel, isScrolling } = this.state;
        return (React.createElement(LazyLoadList, { className: styles.messageList, hasPrev: hasPrevMessages, hasNext: hasNextMessages, onLoadPrev: () => loadMoreMessages(MessagesLoading.PREV), onLoadNext: () => loadMoreMessages(MessagesLoading.NEXT), loadingPrev: MessagesLoading.PREV === messagesLoading, loadingNext: MessagesLoading.NEXT === messagesLoading, canLoad: MessagesLoading.ALL !== messagesLoading, renderLoader: (dir) => this.renderMessagesLoader(dir), containerRef: this.rootRef },
            MessagesLoading.ALL === messagesLoading &&
                React.createElement(LoadingScreen, null),
            messages.length > 0 && currentDateLabel && (React.createElement(PrettyDateBoxStatic, { text: currentDateLabel, visible: isScrolling })),
            this.renderMessages(),
            this.renderForwardMessagesModal(),
            React.createElement(VisibilitySensor, { onChange: (isVisible) => this.checkIfOnBottom(isVisible) },
                React.createElement("div", { className: styles.bottomBlock, ref: this.messagesEndRef }))));
    }
}
export default withStore(observer(MessagesList));
