var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { Trans } from "react-i18next";
import classnames from "classnames";
import { Typography, Tooltip } from "antd";
import Icon, { ExclamationCircleOutlined, EllipsisOutlined, FileFilled } from "@ant-design/icons";
import { MEDIA_TYPE, Permissions } from "@whatsper/texterchat-common";
import PDFIcon from "../../../../assets/icons/file/PDFIcon.png";
import downloadIcon from "../../../../assets/icons/download/DownloadIcon.png";
import downloadIconSVG from "../../../../assets/icons/download/DownloadIconSVG.svg";
import { ReactComponent as Warning } from "../../../../assets/icons/warnings/warning.svg";
import { ReactComponent as singleCheck } from "../../../../assets/messageStatuses/SingleWhiteCheck.svg";
import { ReactComponent as Bug } from "../../../../assets/icons/warnings/bug.svg";
import { getFileUrl } from "../../../../store/FilesStore";
import InfectedModal from "./InfectedModal";
import { useStore } from "../../../../store/rootStore";
import styles from "./DocumentMessage.module.scss";
const { Text } = Typography;
const DocumentMessage = ({ message, channel, shareFile }) => {
    var _a, _b, _c;
    const [modalOpen, setModalOpen] = useState(false);
    const type = (_a = message.media.contentType) === null || _a === void 0 ? void 0 : _a.split("/")[0];
    const media = message.media[0];
    const { agentsStore } = useStore();
    const ext = (_c = (_b = media.filename) === null || _b === void 0 ? void 0 : _b.split(".")) === null || _c === void 0 ? void 0 : _c[1];
    const isDangerFile = media.scan &&
        ((media.scan.isInfected && Object.values(media.scan.isInfected).includes(true)) ||
            (media.scan.isSuspicious && Object.values(media.scan.isSuspicious).includes(true)));
    /**
     * This is temporary implementation till designed styles ready!
     */
    const renderScanWarnings = () => {
        var _a, _b;
        if (!media.scan)
            return null;
        if (!media.scanned) {
            return (React.createElement("div", { className: styles.infectedDocumentContainer },
                React.createElement(ExclamationCircleOutlined, null),
                React.createElement(Text, { className: classnames(styles.infectedDocumentText, styles.infectedDocumentTextNotScanned) }, React.createElement(Trans, { i18nKey: "notScannedFileDetected" }))));
        }
        if (media.scanning) {
            return (React.createElement("div", { className: styles.infectedDocumentContainer },
                React.createElement(EllipsisOutlined, { className: styles.infectedDocumentSvgPending }),
                React.createElement(Text, { className: classnames(styles.infectedDocumentText, styles.infectedDocumentTextPending) }, React.createElement(Trans, { i18nKey: "scanningFile" }))));
        }
        if (media.scanned && media.scan) {
            return (React.createElement("div", { className: classnames(styles.infectedDocumentContainer, {
                    [styles.infectedDocumentInfected]: Object.values(media.scan.isInfected).includes(true),
                    [styles.infectedDocumentSuspicious]: Object.values(media.scan.isSuspicious).includes(true),
                    [styles.infectedDocumentClean]: !Object.values(media.scan.isInfected).includes(true) &&
                        !Object.values(media.scan.isSuspicious).includes(true),
                }) },
                !((_a = media.scan.isInfected) === null || _a === void 0 ? void 0 : _a.scanServiceName) && !((_b = media.scan.isSuspicious) === null || _b === void 0 ? void 0 : _b.scanServiceName) ? (React.createElement(Icon, { className: "infectedDocumentIcon", component: singleCheck })) : (React.createElement(Icon, { className: "infectedDocumentIcon", component: Warning })),
                React.createElement(Text, { className: styles.infectedDocumentText },
                    Object.values(media.scan.isInfected).includes(true) && React.createElement(Trans, { i18nKey: "dangerousFileDetected" }),
                    Object.values(media.scan.isSuspicious).includes(true) && React.createElement(Trans, { i18nKey: "suspiciousFileDetected" }),
                    !Object.values(media.scan.isInfected).includes(true) &&
                        !Object.values(media.scan.isSuspicious).includes(true) && React.createElement(Trans, { i18nKey: "cleanFileDetected" })),
                React.createElement(InfectedModal, { visible: modalOpen, onClose: () => setModalOpen(false), onDownload: handleDownload })));
        }
        return null;
    };
    const handleDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(media.scan && media.scanned) &&
            !agentsStore.hasPermissions([Permissions.Permission.files_allow_download_not_scanned])) {
            return;
        }
        if (isDangerFile && !agentsStore.hasPermissions([Permissions.Permission.files_allow_download_infected])) {
            return;
        }
        let downloadUrl = media.url;
        if (media.fileId) {
            downloadUrl = getFileUrl(media.fileId, true, media.filename);
            if (window.ReactNativeWebView) {
                downloadUrl = yield shareFile(media.fileId);
            }
            ;
        }
        if (!downloadUrl) {
            console.error("Message have no file ID or URL");
            return;
        }
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                event: "openURL",
                url: downloadUrl
            }));
        }
        else {
            window.open(downloadUrl, '_blank');
        }
    });
    const shortenName = () => {
        var _a;
        let shortName = (_a = media.filename) === null || _a === void 0 ? void 0 : _a.substring(0, 16);
        if ((shortName === null || shortName === void 0 ? void 0 : shortName.length) === 16)
            shortName = shortName + "...";
        return shortName || (type === MEDIA_TYPE.VIDEO ? "Video" : "Document");
    };
    const openModal = () => {
        setModalOpen(true);
    };
    return (React.createElement("div", { className: classnames(styles.container, { [styles.incoming]: message.incoming }), onClick: handleDownload },
        React.createElement("div", { className: styles.content },
            ext === "pdf" ? (React.createElement("img", { className: styles.icon, src: PDFIcon })) : (React.createElement(Icon, { className: styles.icon, component: FileFilled })),
            React.createElement("p", { className: classnames(styles.text, { [styles.outgoingText]: !message.incoming }), title: media.caption || media.filename }, shortenName()),
            message.media.scan && (React.createElement(Tooltip, { placement: "bottom", color: "black", title: isDangerFile && !agentsStore.hasPermissions([Permissions.Permission.files_allow_download_infected]) && (React.createElement("div", { className: styles.infectedDocumentTooltip },
                    React.createElement(Icon, { className: styles.infectedDocumentTooltipIcon, component: Bug }),
                    React.createElement(Text, { className: styles.infectedDocumentTooltipText },
                        React.createElement(Trans, { i18nKey: "cantDownloadFile" })))) },
                React.createElement("img", { className: classnames(styles.icon, styles.grey, {
                        [styles.white]: !message.incoming,
                    }), src: message.incoming ? downloadIcon : downloadIconSVG })))),
        renderScanWarnings()));
};
export default DocumentMessage;
