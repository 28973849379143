import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { MessagesSearchLoading } from '../../../store/MessagesStore';
import MessagesSearchHit from '../MessagesSearchHit';
import LazyLoadList from '../../Base/Utils/LazyLoadList';
class MessagesSearchList extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "renderItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { search, onClickMessage, results, loading } = this.props;
                if (loading === MessagesSearchLoading.ALL) {
                    // TODO: Skeleton animation???
                    return null;
                }
                return results.map((hit) => {
                    return (React.createElement(MessagesSearchHit, { key: hit.id, search: search, hit: hit, onClick: () => onClickMessage ? onClickMessage(hit.id, hit.chatId) : null }));
                });
            }
        });
    }
    render() {
        const { id, className, loading, hasMore, onLoadMore } = this.props;
        return (React.createElement(LazyLoadList, { id: id, className: className, loadingNext: loading === MessagesSearchLoading.MORE, canLoad: loading === MessagesSearchLoading.NONE, hasNext: hasMore, onLoadNext: () => onLoadMore === null || onLoadMore === void 0 ? void 0 : onLoadMore() },
            this.props.header,
            this.renderItems()));
    }
}
Object.defineProperty(MessagesSearchList, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        skeletonCountDefault: 10,
        skeletonCountMax: 20,
    }
});
export default observer(MessagesSearchList);
